define('mobiledoc-kit', ['exports', 'mobiledoc-kit/editor/editor', 'mobiledoc-kit/editor/ui', 'mobiledoc-kit/cards/image', 'mobiledoc-kit/utils/cursor/range', 'mobiledoc-kit/utils/cursor/position', 'mobiledoc-kit/utils/mobiledoc-error', 'mobiledoc-kit/version', 'mobiledoc-kit/renderers/mobiledoc'], function (exports, _mobiledocKitEditorEditor, _mobiledocKitEditorUi, _mobiledocKitCardsImage, _mobiledocKitUtilsCursorRange, _mobiledocKitUtilsCursorPosition, _mobiledocKitUtilsMobiledocError, _mobiledocKitVersion, _mobiledocKitRenderersMobiledoc) {
  'use strict';

  exports.registerGlobal = registerGlobal;

  var Mobiledoc = {
    Editor: _mobiledocKitEditorEditor['default'],
    UI: _mobiledocKitEditorUi,
    ImageCard: _mobiledocKitCardsImage['default'],
    Range: _mobiledocKitUtilsCursorRange['default'],
    Position: _mobiledocKitUtilsCursorPosition['default'],
    Error: _mobiledocKitUtilsMobiledocError['default'],
    VERSION: _mobiledocKitVersion['default'],
    MOBILEDOC_VERSION: _mobiledocKitRenderersMobiledoc.MOBILEDOC_VERSION
  };

  function registerGlobal(global) {
    global.Mobiledoc = Mobiledoc;
  }

  exports.Editor = _mobiledocKitEditorEditor['default'];
  exports.UI = _mobiledocKitEditorUi;
  exports.Range = _mobiledocKitUtilsCursorRange['default'];
  exports.Position = _mobiledocKitUtilsCursorPosition['default'];
  exports.MOBILEDOC_VERSION = _mobiledocKitRenderersMobiledoc.MOBILEDOC_VERSION;
  exports['default'] = Mobiledoc;
});