define('mobiledoc-kit/utils/to-range', ['exports', 'mobiledoc-kit/utils/cursor/range', 'mobiledoc-kit/utils/cursor/position', 'mobiledoc-kit/utils/assert'], function (exports, _mobiledocKitUtilsCursorRange, _mobiledocKitUtilsCursorPosition, _mobiledocKitUtilsAssert) {
  'use strict';

  exports['default'] = toRange;

  function toRange(rangeLike) {
    (0, _mobiledocKitUtilsAssert['default'])('Must pass non-blank object to "toRange"', !!rangeLike);

    if (rangeLike instanceof _mobiledocKitUtilsCursorRange['default']) {
      return rangeLike;
    } else if (rangeLike instanceof _mobiledocKitUtilsCursorPosition['default']) {
      return rangeLike.toRange();
    }

    (0, _mobiledocKitUtilsAssert['default'])('Incorrect structure for rangeLike: ' + rangeLike, false);
  }
});