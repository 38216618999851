define('ember-g-map/components/g-map', ['exports', 'ember-g-map/templates/components/g-map'], function (exports, _gMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _gMap.default,
    classNames: ['g-map'],
    bannedOptions: Ember.A(['center', 'zoom']),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('markers', Ember.A());
      this.set('polylines', Ember.A());
      if (Ember.isEmpty(this.get('options'))) {
        this.set('options', {});
      }
    },


    permittedOptions: Ember.computed('options', function () {
      var _getProperties = this.getProperties(['options', 'bannedOptions']),
          options = _getProperties.options,
          bannedOptions = _getProperties.bannedOptions;

      var permittedOptions = {};
      for (var option in options) {
        if (options.hasOwnProperty(option) && !bannedOptions.includes(option)) {
          permittedOptions[option] = options[option];
        }
      }
      return permittedOptions;
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (Ember.isEmpty(this.get('map')) && typeof FastBoot === 'undefined' && typeof google !== 'undefined') {
        var canvas = this.$().find('.g-map-canvas').get(0);
        var options = this.get('permittedOptions');
        this.set('map', new google.maps.Map(canvas, options));
      }
      this.setZoom();
      this.setCenter();
      if (this.get('shouldFit')) {
        this.fitToMarkers();
      }
    },


    permittedOptionsChanged: Ember.observer('permittedOptions', function () {
      Ember.run.once(this, 'setOptions');
    }),

    setOptions: function setOptions() {
      var map = this.get('map');
      var options = this.get('permittedOptions');
      if (Ember.isPresent(map)) {
        map.setOptions(options);
      }
    },


    zoomChanged: Ember.observer('zoom', function () {
      Ember.run.once(this, 'setZoom');
    }),

    setZoom: function setZoom() {
      var map = this.get('map');
      var zoom = this.get('zoom');
      if (Ember.isPresent(map)) {
        map.setZoom(zoom);
      }
    },


    coordsChanged: Ember.observer('lat', 'lng', function () {
      Ember.run.once(this, 'setCenter');
    }),

    setCenter: function setCenter() {
      var map = this.get('map');
      var lat = this.get('lat');
      var lng = this.get('lng');

      if (Ember.isPresent(map) && Ember.isPresent(lat) && Ember.isPresent(lng) && typeof FastBoot === 'undefined' && typeof google !== 'undefined') {
        var center = new google.maps.LatLng(lat, lng);
        map.setCenter(center);
      }
    },
    registerMarker: function registerMarker(marker) {
      this.get('markers').addObject(marker);
    },
    unregisterMarker: function unregisterMarker(marker) {
      this.get('markers').removeObject(marker);
    },
    registerPolyline: function registerPolyline(polyline) {
      this.get('polylines').addObject(polyline);
    },
    unregisterPolyline: function unregisterPolyline(polyline) {
      this.get('polylines').removeObject(polyline);
    },


    shouldFit: Ember.computed('markersFitMode', function () {
      return Ember.A(['init', 'live']).includes(this.get('markersFitMode'));
    }),

    markersChanged: Ember.observer('markers.@each.lat', 'markers.@each.lng', function () {
      if (this.get('markersFitMode') === 'live') {
        Ember.run.once(this, 'fitToMarkers');
      }
    }),

    fitToMarkers: function fitToMarkers() {
      var markers = this.get('markers').filter(function (marker) {
        return Ember.isPresent(marker.get('lat')) && Ember.isPresent(marker.get('lng'));
      });

      if (markers.length === 0 || typeof FastBoot !== 'undefined') {
        return;
      }

      var map = this.get('map');
      var bounds = new google.maps.LatLngBounds();

      markers.forEach(function (marker) {
        if (Ember.isPresent(marker.get('viewport'))) {
          bounds.union(marker.get('viewport'));
        } else {
          bounds.extend(new google.maps.LatLng(marker.get('lat'), marker.get('lng')));
        }
      });
      map.fitBounds(bounds);
    },
    groupMarkerClicked: function groupMarkerClicked(marker, group) {
      var markers = Ember.A(this.get('markers').without(marker)).filterBy('group', group);
      markers.forEach(function (marker) {
        return marker.closeInfowindow();
      });
    }
  });
});