define('ember-mobiledoc-editor/utils/create-component-card', ['exports', 'ember-mobiledoc-editor/components/mobiledoc-editor/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = createComponentCard;
  var RENDER_TYPE = 'dom';

  function renderFallback(doc) {
    var element = doc.createElement('div');
    var text = doc.createTextNode('[placeholder for Ember component card]');
    element.appendChild(text);
    return element;
  }

  function createComponentCard(name) {
    var doc = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window.document;


    return {
      name: name,
      type: RENDER_TYPE,
      render: function render(cardArg) {
        var env = cardArg.env,
            options = cardArg.options;

        if (!options[_component.ADD_CARD_HOOK]) {
          return renderFallback(doc);
        }

        var _options$ADD_CARD_HOO = options[_component.ADD_CARD_HOOK](cardArg),
            card = _options$ADD_CARD_HOO.card,
            element = _options$ADD_CARD_HOO.element;

        var onTeardown = env.onTeardown;


        onTeardown(function () {
          return options[_component.REMOVE_CARD_HOOK](card);
        });

        return element;
      },
      edit: function edit(cardArg) {
        var env = cardArg.env,
            options = cardArg.options;

        if (!options[_component.ADD_CARD_HOOK]) {
          return renderFallback(doc);
        }

        var isEditing = true;

        var _options$ADD_CARD_HOO2 = options[_component.ADD_CARD_HOOK](cardArg, isEditing),
            card = _options$ADD_CARD_HOO2.card,
            element = _options$ADD_CARD_HOO2.element;

        var onTeardown = env.onTeardown;


        onTeardown(function () {
          return options[_component.REMOVE_CARD_HOOK](card);
        });

        return element;
      }
    };
  }
});