define('ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/index', ['exports', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/renderer-factory', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/render-type'], function (exports, _rendererFactory, _renderType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.RENDER_TYPE = undefined;
  exports.registerGlobal = registerGlobal;
  exports.RENDER_TYPE = _renderType.default;
  function registerGlobal(window) {
    window.MobiledocDOMRenderer = _rendererFactory.default;
  }

  exports.default = _rendererFactory.default;
});