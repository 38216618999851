define("ember-ajax/-private/utils/parse-response-headers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseResponseHeaders;
  _exports.CRLF = void 0;

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var CRLF = "\r\n";
  _exports.CRLF = CRLF;

  function parseResponseHeaders(headersString) {
    var headers = {};

    if (!headersString) {
      return headers;
    }

    return headersString.split(CRLF).reduce(function (hash, header) {
      var _header$split = header.split(':'),
          _header$split2 = _toArray(_header$split),
          field = _header$split2[0],
          value = _header$split2.slice(1);

      field = field.trim();
      var valueString = value.join(':').trim();

      if (valueString) {
        hash[field] = valueString;
      }

      return hash;
    }, headers);
  }
});