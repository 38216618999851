define('ember-mobiledoc-dom-renderer/utils/polyfilled-assign', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var merge = Ember.merge,
      assign = Ember.assign;


  var polyfilledAssign = assign || merge;

  exports.default = polyfilledAssign;
});