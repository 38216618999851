define('mobiledoc-kit/cards/image', ['exports', 'mobiledoc-kit/utils/placeholder-image-src'], function (exports, _mobiledocKitUtilsPlaceholderImageSrc) {
  'use strict';

  exports['default'] = {
    name: 'image',
    type: 'dom',

    render: function render(_ref) {
      var payload = _ref.payload;

      var img = document.createElement('img');
      img.src = payload.src || _mobiledocKitUtilsPlaceholderImageSrc['default'];
      return img;
    }
  };
});