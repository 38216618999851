define('mobiledoc-kit/utils/keys', ['exports'], function (exports) {
  'use strict';

  exports['default'] = {
    BACKSPACE: 'Backspace',
    SPACE: ' ',
    ENTER: 'Enter',
    SHIFT: 'Shift',
    ESC: 'Escape',
    DELETE: 'Delete',
    INS: 'Insert',
    HOME: 'Home',
    END: 'End',
    PAGEUP: 'PageUp',
    PAGEDOWN: 'PageDown',
    CLEAR: 'Clear',
    PAUSE: 'Pause',
    TAB: 'Tab',
    ALT: 'Alt',
    CTRL: 'Control',

    LEFT: 'ArrowLeft',
    RIGHT: 'ArrowRight',
    UP: 'ArrowUp',
    DOWN: 'ArrowDown'
  };
});