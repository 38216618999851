define('ember-inputmask/components/email-input', ['exports', 'ember-inputmask/components/input-mask'], function (exports, _inputMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _inputMask.default.extend({
    oldComponent: '{{email-input}}',
    newComponent: '{{one-way-email-mask}}',
    mask: 'email'
  });
});