define('ember-mobiledoc-editor/components/mobiledoc-markup-button/component', ['exports', 'ember-mobiledoc-editor/components/mobiledoc-markup-button/template', 'ember-mobiledoc-editor/utils/titleize'], function (exports, _template, _titleize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    layout: _template.default,
    attributeBindings: ['type', 'title'],
    classNameBindings: ['isActive:active'],
    type: 'button',
    init: function init() {
      this._super.apply(this, arguments);
      this._updateIsActiveCP();
    },

    onForDidChange: Ember.observer('for', function () {
      this._updateIsActiveCP();
    }),
    _updateIsActiveCP: function _updateIsActiveCP() {
      var forProperty = this.get('for');
      var fullPath = 'editor.activeMarkupTagNames.is' + (0, _titleize.default)(forProperty);
      var cp = Ember.computed(fullPath, function () {
        return this.get(fullPath);
      });
      Ember.defineProperty(this, 'isActive', cp);
    },
    click: function click() {
      var editor = this.get('editor');
      var forProperty = this.get('for');
      editor.toggleMarkup(forProperty);
    }
  });
});