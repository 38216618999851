define("ember-modal-dialog/templates/components/deprecated-tether-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QUGUDbYB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\",\"renderInPlace\"],[[24,[\"destinationElementId\"]],[24,[\"renderInPlace\"]]]],{\"statements\":[[4,\"if\",[[24,[\"hasOverlay\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[22,\"overlayClassNamesString\"]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"onClickOverlay\"],null]],null]],[10,\"tabindex\",\"-1\"],[10,\"data-emd-overlay\",\"\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[24,[\"renderInPlace\"]]],null,{\"statements\":[[4,\"ember-modal-dialog-positioned-container\",null,[[\"class\",\"targetAttachment\",\"target\",\"renderInPlace\"],[[24,[\"containerClassNamesString\"]],[24,[\"targetAttachment\"]],[24,[\"target\"]],[24,[\"renderInPlace\"]]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"ember-tether\",null,[[\"class\",\"target\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"classPrefix\",\"offset\",\"targetOffset\",\"constraints\"],[[24,[\"containerClassNamesString\"]],[24,[\"target\"]],[24,[\"attachment\"]],[24,[\"targetAttachment\"]],[24,[\"targetModifier\"]],[24,[\"tetherClassPrefix\"]],[24,[\"offset\"]],[24,[\"targetOffset\"]],[24,[\"constraints\"]]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/deprecated-tether-dialog.hbs"
    }
  });

  _exports.default = _default;
});