define('ember-feature-flags/helpers/feature-flag', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    features: Ember.inject.service(),

    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          flag = _ref2[0];

      if (this._observedFlag) {
        this.get('features').removeObserver(this._observedFlag, this, 'recompute');
      }

      this.set('_observedFlag', Ember.String.camelize(flag));
      this.get('features').addObserver(this._observedFlag, this, 'recompute');

      return this.get('features').isEnabled(flag);
    },


    _observedFlag: null,

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (this._observedFlag) {
        this.get('features').removeObserver(this._observedFlag, this, 'recompute');
      }
    }
  });
});