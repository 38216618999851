define("ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/section-types", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var MARKUP_SECTION_TYPE = exports.MARKUP_SECTION_TYPE = 1;
  var IMAGE_SECTION_TYPE = exports.IMAGE_SECTION_TYPE = 2;
  var LIST_SECTION_TYPE = exports.LIST_SECTION_TYPE = 3;
  var CARD_SECTION_TYPE = exports.CARD_SECTION_TYPE = 10;
});