define('ember-perfect-scrollbar/mixins/perfect-scrollbar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin,
      get = Ember.get,
      isPresent = Ember.isPresent;
  exports.default = Mixin.create({
    perfectScrollbarOptions: {},

    init: function init() {
      this._super.apply(this, arguments);

      var resizeService = get(this, 'resizeService');

      if (isPresent(resizeService)) {
        resizeService.on('debouncedDidResize', this, "_resizePerfectScrollbar");
      }
    },
    _resizePerfectScrollbar: function _resizePerfectScrollbar() {
      PerfectScrollbar.update(this.element);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      PerfectScrollbar.initialize(this.element, get(this, 'perfectScrollbarOptions'));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var resizeService = get(this, 'resizeService');

      if (isPresent(resizeService)) {
        resizeService.off('debouncedDidResize', this, "_resizePerfectScrollbar");
      }

      PerfectScrollbar.destroy(this.element);
    }
  });
});