define('ember-inputmask/components/zip-code-input', ['exports', 'ember-inputmask/components/input-mask'], function (exports, _inputMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _inputMask.default.extend({
    mask: '99999',

    fullCode: false,

    oldComponent: '{{zip-code-input}}',
    newComponent: '{{one-way-zip-code-mask}}',

    updateMask: function updateMask() {
      if (this.get('fullCode')) {
        this.set('mask', '99999[-9999]');
      }

      this._super();
    },

    _maskShouldChange: Ember.observer('mask', 'fullCode', function () {
      Ember.run.once(this, 'updateMask');
    })
  });
});