define('ember-g-map/utils/compact', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (objectInstance) {
    var compactedObject = {};

    for (var key in objectInstance) {
      var value = objectInstance[key];

      if (Ember.isPresent(value)) {
        compactedObject[key] = value;
      }
    }

    return compactedObject;
  };
});