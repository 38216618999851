define('ember-g-map/components/g-map-polyline', ['exports', 'ember-g-map/templates/components/g-map-polyline', 'ember-g-map/components/g-map', 'ember-g-map/utils/compact'], function (exports, _gMapPolyline, _gMap, _compact) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var allowedPolylineOptions = Ember.A(['strokeColor', 'strokeWeight', 'strokeOpacity', 'zIndex', 'geodesic', 'icons', 'clickable', 'draggable', 'visible', 'path']);

  var GMapPolylineComponent = Ember.Component.extend({
    layout: _gMapPolyline.default,
    classNames: ['g-map-polyline'],

    map: Ember.computed.alias('mapContext.map'),

    init: function init() {
      this._super.apply(this, arguments);
      this.infowindow = null;
      this.set('coordinates', Ember.A());
      if (Ember.isEmpty(this.get('group'))) {
        this.set('group', null);
      }

      var mapContext = this.get('mapContext');
      (false && !(mapContext instanceof _gMap.default) && Ember.assert('Must be inside {{#g-map}} component with context set', mapContext instanceof _gMap.default));


      mapContext.registerPolyline(this);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (Ember.isEmpty(this.get('polyline')) && typeof google !== 'undefined') {
        var options = (0, _compact.default)(this.getProperties(allowedPolylineOptions));
        var polyline = new google.maps.Polyline(options);
        this.set('polyline', polyline);
      }
      this.setMap();
      this.setPath();
      this.updatePolylineOptions();
      this.setOnClick();
      this.setOnDrag();
    },
    willDestroyElement: function willDestroyElement() {
      this.unsetPolylineFromMap();
      this.get('mapContext').unregisterPolyline(this);
    },
    registerCoordinate: function registerCoordinate(coordinate) {
      this.get('coordinates').addObject(coordinate);
    },
    unregisterCoordinate: function unregisterCoordinate(coordinate) {
      this.get('coordinates').removeObject(coordinate);
      this.setPath();
    },
    unsetPolylineFromMap: function unsetPolylineFromMap() {
      var polyline = this.get('polyline');
      if (Ember.isPresent(polyline)) {
        polyline.setMap(null);
      }
    },


    mapWasSet: Ember.observer('map', function () {
      Ember.run.once(this, 'setMap');
    }),

    setMap: function setMap() {
      var map = this.get('map');
      var polyline = this.get('polyline');

      if (Ember.isPresent(polyline) && Ember.isPresent(map)) {
        polyline.setMap(map);
      }
    },
    setPath: function setPath() {
      var polyline = this.get('polyline');
      var coordinates = this.get('coordinates');
      var path = this.get('path');

      if (Ember.isPresent(polyline)) {

        if (Ember.isPresent(coordinates) && Ember.isEmpty(path)) {
          var coordArray = Ember.A(this.get('coordinates').mapBy('coordinate')).compact();
          polyline.setPath(coordArray);
        }

        if (Ember.isPresent(path) && Ember.isEmpty(coordinates)) {
          polyline.setPath(path);
        }
      }
    },


    polylineOptionsChanged: Ember.observer.apply(undefined, _toConsumableArray(allowedPolylineOptions).concat([function () {
      Ember.run.once(this, 'updatePolylineOptions');
    }])),

    updatePolylineOptions: function updatePolylineOptions() {
      var polyline = this.get('polyline');
      var options = (0, _compact.default)(this.getProperties(allowedPolylineOptions));

      if (Ember.isPresent(polyline) && Ember.isPresent(Object.keys(options))) {
        polyline.setOptions(options);
      }
    },
    setOnClick: function setOnClick() {
      var _this = this;

      var polyline = this.get('polyline');
      if (Ember.isPresent(polyline)) {
        polyline.addListener('click', function (e) {
          return _this.sendOnClick(e);
        });
      }
    },
    sendOnClick: function sendOnClick(e) {
      var onClick = this.get('onClick');
      var polyline = this.get('polyline');

      if (Ember.typeOf(onClick) === 'function') {
        onClick(e, polyline);
      } else {
        this.sendAction('onClick', e, polyline);
      }
    },
    setOnDrag: function setOnDrag() {
      var _this2 = this;

      var polyline = this.get('polyline');
      if (Ember.isPresent(polyline)) {
        polyline.addListener('dragend', function (e) {
          return _this2.sendOnDrag(e);
        });
      }
    },
    sendOnDrag: function sendOnDrag(e) {
      var onDrag = this.get('onDrag');
      var polyline = this.get('polyline');

      if (Ember.typeOf(onDrag) === 'function') {
        onDrag(e, polyline);
      } else {
        this.sendAction('onDrag', e, polyline);
      }
    }
  });

  GMapPolylineComponent.reopenClass({
    positionalParams: ['mapContext']
  });

  exports.default = GMapPolylineComponent;
});