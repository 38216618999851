define('ember-mobiledoc-editor/utils/polyfill-assign', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var polyfilledAssign = Ember.assign || Ember.merge;

  exports.default = polyfilledAssign;
});