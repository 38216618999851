define('ember-simple-auth/utils/symbol', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /**
   * This symbol provides a Symbol replacement for browsers that do not have it
   * (eg. IE 11).
   *
   * The replacement is different from the native Symbol in some ways. It is a
   * function that produces an output:
   * - iterable;
   * - that is a string, not a symbol.
   *
   * @internal
   */
  var symbol = exports.symbol = typeof Symbol !== 'undefined' ? Symbol : function (key) {
    return '__' + key + Math.floor(Math.random() * Date.now()) + '__';
  };
});