define('ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/cards/image', ['exports', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/render-type'], function (exports, _renderType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'image',
    type: _renderType.default,
    render: function render(_ref) {
      var payload = _ref.payload,
          dom = _ref.env.dom;

      var img = dom.createElement('img');
      img.src = payload.src;
      return img;
    }
  };
});