define('ember-mobiledoc-editor/components/mobiledoc-toolbar/component', ['exports', 'ember-mobiledoc-editor/components/mobiledoc-toolbar/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'ul',
    classNames: ['mobiledoc-toolbar']
  });
});