define('mobiledoc-dom-renderer/utils/render-utils', ['exports', 'mobiledoc-dom-renderer/utils/tag-names', 'mobiledoc-dom-renderer/utils/sanitization-utils'], function (exports, _mobiledocDomRendererUtilsTagNames, _mobiledocDomRendererUtilsSanitizationUtils) {
  'use strict';

  exports.defaultSectionElementRenderer = defaultSectionElementRenderer;
  exports.defaultMarkupElementRenderer = defaultMarkupElementRenderer;

  function defaultSectionElementRenderer(tagName, dom) {
    var element = undefined;
    if ((0, _mobiledocDomRendererUtilsTagNames.isMarkupSectionElementName)(tagName)) {
      element = dom.createElement(tagName);
    } else {
      element = dom.createElement('div');
      element.setAttribute('class', tagName);
    }

    return element;
  }

  function sanitizeAttribute(tagName, attrName, attrValue) {
    if (tagName === 'a' && attrName === 'href') {
      return (0, _mobiledocDomRendererUtilsSanitizationUtils.sanitizeHref)(attrValue);
    } else {
      return attrValue;
    }
  }

  function defaultMarkupElementRenderer(tagName, dom, attrsObj) {
    var element = dom.createElement(tagName);
    Object.keys(attrsObj).forEach(function (attrName) {
      var attrValue = attrsObj[attrName];
      attrValue = sanitizeAttribute(tagName, attrName, attrValue);
      element.setAttribute(attrName, attrValue);
    });
    return element;
  }
});