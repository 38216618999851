define('ember-g-map/components/g-map-infowindow', ['exports', 'ember-g-map/templates/components/g-map-infowindow', 'ember-g-map/components/g-map', 'ember-g-map/components/g-map-marker', 'ember-g-map/utils/compact'], function (exports, _gMapInfowindow, _gMap, _gMapMarker, _compact) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var allowedOptions = Ember.A(['disableAutoPan', 'maxWidth', 'pixelOffset']);

  var OPEN_CLOSE_EVENTS = Ember.A(['click', 'dblclick', 'rightclick', 'mouseover', 'mouseout']);

  var GMapInfowindowComponent = Ember.Component.extend({
    layout: _gMapInfowindow.default,
    classNames: ['g-map-marker'],

    map: Ember.computed.alias('mapContext.map'),
    marker: Ember.computed.alias('mapContext.marker'),

    init: function init() {
      this._super.apply(this, arguments);

      var mapContext = this.get('mapContext');
      var hasMap = mapContext instanceof _gMap.default;
      var hasMarker = mapContext instanceof _gMapMarker.default;
      (false && !(hasMarker || hasMap) && Ember.assert('Must be inside {{#g-map}} or {{#g-map-marker}} components with context set', hasMarker || hasMap));


      this.set('hasMarker', hasMarker);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (Ember.isEmpty(this.get('infowindow'))) {
        var infowindow = this.buildInfowindow();
        this.set('infowindow', infowindow);
      }
      this.setPosition();
      this.setMap();
      this.setMarker();
      this.setOptions();
    },
    willDestroyElement: function willDestroyElement() {
      this.close();

      if (this.get('hasMarker')) {
        this.get('mapContext').unregisterInfowindow();
      }
    },


    optionsChanged: Ember.observer.apply(undefined, _toConsumableArray(allowedOptions).concat([function () {
      Ember.run.once(this, 'setOptions');
    }])),

    setOptions: function setOptions() {
      var infowindow = this.get('infowindow');
      var options = (0, _compact.default)(this.getProperties(allowedOptions));

      if (Ember.isPresent(infowindow) && Ember.isPresent(Object.keys(options))) {
        infowindow.setOptions(options);
      }
    },
    buildInfowindow: function buildInfowindow() {
      var _this = this;

      if (typeof google !== 'undefined') {
        var infowindow = new google.maps.InfoWindow({
          content: this.get('element')
        });

        if (Ember.isPresent(this.get('attrs.onOpen'))) {
          infowindow.addListener('domready', function () {
            return _this.handleOpenClickEvent();
          });
        }

        if (Ember.isPresent(this.get('attrs.onClose'))) {
          infowindow.addListener('closeclick', function () {
            return _this.handleCloseClickEvent();
          });
        }
        return infowindow;
      }
    },
    handleOpenClickEvent: function handleOpenClickEvent() {
      var onOpen = this.get('onOpen');
      if (Ember.typeOf(onOpen) === 'function') {
        onOpen();
      } else {
        this.sendAction('onOpen', this);
      }
    },
    handleCloseClickEvent: function handleCloseClickEvent() {
      var onClose = this.get('onClose');
      if (Ember.typeOf(onClose) === 'function') {
        onClose();
      } else {
        this.sendAction('onClose');
      }
    },
    open: function open() {
      var infowindow = this.get('infowindow');
      var map = this.get('map');
      var marker = this.get('marker');

      this.set('isOpen', true);
      if (Ember.isPresent(map) && Ember.isPresent(marker)) {
        infowindow.open(map, marker);
      } else if (Ember.isPresent(map)) {
        infowindow.open(map);
      }
    },
    close: function close() {
      var infowindow = this.get('infowindow');
      if (Ember.isPresent(infowindow)) {
        this.set('isOpen', false);
        infowindow.close();
      }
    },


    mapWasSet: Ember.observer('map', function () {
      Ember.run.once(this, 'setMap');
    }),

    setMap: function setMap() {
      if (this.get('hasMarker') === false) {
        this.open();
      }
    },


    markerWasSet: Ember.observer('marker', function () {
      Ember.run.once(this, 'setMarker');
    }),

    setMarker: function setMarker() {
      var map = this.get('map');
      var marker = this.get('marker');
      var context = this.get('mapContext');
      var infowindow = this.get('infowindow');

      if (Ember.isPresent(infowindow) && Ember.isPresent(map) && Ember.isPresent(marker)) {
        var openEvent = this.retrieveOpenEvent();
        var closeEvent = this.retrieveCloseEvent();
        context.registerInfowindow(this, openEvent, closeEvent);
      }
    },


    coordsChanged: Ember.observer('lat', 'lng', function () {
      Ember.run.once(this, 'setPosition');
    }),

    setPosition: function setPosition() {
      var infowindow = this.get('infowindow');
      var lat = this.get('lat');
      var lng = this.get('lng');

      if (Ember.isPresent(infowindow) && Ember.isPresent(lat) && Ember.isPresent(lng) && typeof FastBoot === 'undefined') {
        var position = new google.maps.LatLng(lat, lng);
        infowindow.setPosition(position);
      }
    },
    retrieveOpenEvent: function retrieveOpenEvent() {
      var openEvent = this.get('openOn');
      return OPEN_CLOSE_EVENTS.includes(openEvent) ? openEvent : 'click';
    },
    retrieveCloseEvent: function retrieveCloseEvent() {
      var closeEvent = this.get('closeOn');
      return OPEN_CLOSE_EVENTS.includes(closeEvent) ? closeEvent : null;
    }
  });

  GMapInfowindowComponent.reopenClass({
    positionalParams: ['mapContext']
  });

  exports.default = GMapInfowindowComponent;
});