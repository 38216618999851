define('ember-mobiledoc-editor/utils/create-component-atom', ['exports', 'ember-mobiledoc-editor/components/mobiledoc-editor/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = createComponentAtom;
  var RENDER_TYPE = 'dom';

  function renderFallback(doc) {
    var element = document.createElement('span');
    var text = doc.createTextNode('[placeholder for Ember atom]');
    element.appendChild(text);
    return element;
  }

  function createComponentAtom(name) {
    var doc = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window.document;


    return {
      name: name,
      type: RENDER_TYPE,
      render: function render(atomArg) {
        var env = atomArg.env,
            options = atomArg.options;

        if (!options[_component.ADD_ATOM_HOOK]) {
          return renderFallback(doc);
        }

        var _options$ADD_ATOM_HOO = options[_component.ADD_ATOM_HOOK](atomArg),
            atom = _options$ADD_ATOM_HOO.atom,
            element = _options$ADD_ATOM_HOO.element;

        var onTeardown = env.onTeardown;


        onTeardown(function () {
          return options[_component.REMOVE_ATOM_HOOK](atom);
        });

        return element;
      }
    };
  }
});