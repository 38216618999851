define('ember-g-map/components/g-map-marker', ['exports', 'ember-g-map/templates/components/g-map-marker', 'ember-g-map/components/g-map'], function (exports, _gMapMarker, _gMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var GMapMarkerComponent = Ember.Component.extend({
    layout: _gMapMarker.default,
    classNames: ['g-map-marker'],

    map: Ember.computed.alias('mapContext.map'),

    init: function init() {
      this._super.apply(this, arguments);
      this.infowindow = null;
      if (Ember.isEmpty(this.get('group'))) {
        this.set('group', null);
      }

      var mapContext = this.get('mapContext');
      (false && !(mapContext instanceof _gMap.default) && Ember.assert('Must be inside {{#g-map}} component with context set', mapContext instanceof _gMap.default));


      mapContext.registerMarker(this);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (Ember.isEmpty(this.get('marker')) && typeof FastBoot === 'undefined' && typeof google !== 'undefined') {
        var marker = new google.maps.Marker();
        this.set('marker', marker);
      }
      this.setPosition();
      this.setZIndex();
      this.setIcon();
      this.setDraggable();
      this.setLabel();
      this.setTitle();
      this.setMap();
      this.setOnClick();
      this.setOnDrag();
    },
    willDestroyElement: function willDestroyElement() {
      this.unsetMarkerFromMap();
      this.get('mapContext').unregisterMarker(this);
    },
    registerInfowindow: function registerInfowindow(infowindow, openEvent, closeEvent) {
      this.set('infowindow', infowindow);
      this.attachOpenCloseEvents(infowindow, openEvent, closeEvent);
    },
    unregisterInfowindow: function unregisterInfowindow() {
      this.set('infowindow', null);
    },
    attachOpenCloseEvents: function attachOpenCloseEvents(infowindow, openEvent, closeEvent) {
      var marker = this.get('marker');
      if (openEvent === closeEvent) {
        this.attachTogglingInfowindowEvent(marker, infowindow, openEvent);
      } else {
        this.attachOpenInfowindowEvent(marker, infowindow, openEvent);
        this.attachCloseInfowindowEvent(marker, infowindow, closeEvent);
      }
    },
    attachOpenInfowindowEvent: function attachOpenInfowindowEvent(marker, infowindow, event) {
      if (Ember.isPresent(event)) {
        marker.addListener(event, function () {
          return infowindow.open();
        });
      }
    },
    attachCloseInfowindowEvent: function attachCloseInfowindowEvent(marker, infowindow, event) {
      if (Ember.isPresent(event)) {
        marker.addListener(event, function () {
          return infowindow.close();
        });
      }
    },
    attachTogglingInfowindowEvent: function attachTogglingInfowindowEvent(marker, infowindow, event) {
      if (Ember.isPresent(event)) {
        marker.addListener(event, function () {
          if (infowindow.get('isOpen')) {
            infowindow.close();
          } else {
            infowindow.open();
          }
        });
      }
    },
    unsetMarkerFromMap: function unsetMarkerFromMap() {
      var marker = this.get('marker');
      if (Ember.isPresent(marker)) {
        marker.setMap(null);
      }
    },


    mapWasSet: Ember.observer('map', function () {
      Ember.run.once(this, 'setMap');
    }),

    setMap: function setMap() {
      var map = this.get('map');
      var marker = this.get('marker');

      if (Ember.isPresent(marker) && Ember.isPresent(map)) {
        marker.setMap(map);
      }
    },


    coordsChanged: Ember.observer('lat', 'lng', function () {
      Ember.run.once(this, 'setPosition');
    }),

    setPosition: function setPosition() {
      var marker = this.get('marker');
      var lat = this.get('lat');
      var lng = this.get('lng');

      if (Ember.isPresent(marker) && Ember.isPresent(lat) && Ember.isPresent(lng) && typeof FastBoot === 'undefined') {
        var position = new google.maps.LatLng(lat, lng);
        if (Ember.isPresent(position)) {
          marker.setPosition(position);
        }
      }
    },


    iconChanged: Ember.observer('icon', function () {
      Ember.run.once(this, 'setIcon');
    }),

    setIcon: function setIcon() {
      var marker = this.get('marker');
      var icon = this.get('icon');

      if (Ember.isPresent(marker) && Ember.isPresent(icon)) {
        marker.setIcon(icon);
      }
    },


    zIndexChanged: Ember.observer('zIndex', function () {
      Ember.run.once(this, 'setZIndex');
    }),

    setZIndex: function setZIndex() {
      var marker = this.get('marker');
      var zIndex = this.get('zIndex');
      if (Ember.isPresent(marker) && Ember.isPresent(zIndex)) {
        marker.setZIndex(zIndex);
      }
    },


    draggableChanged: Ember.observer('draggable', function () {
      Ember.run.once(this, 'setDraggable');
    }),

    setDraggable: function setDraggable() {
      var marker = this.get('marker');
      var draggable = this.get('draggable');
      if (Ember.isPresent(marker) && Ember.isPresent(draggable)) {
        marker.setDraggable(draggable);
      }
    },
    setOnClick: function setOnClick() {
      var _this = this;

      var marker = this.get('marker');
      if (Ember.isPresent(marker)) {
        marker.addListener('click', function () {
          return _this.sendOnClick();
        });
      }
    },
    setOnDrag: function setOnDrag() {
      var _this2 = this;

      var marker = this.get('marker');
      marker.addListener('dragend', function (event) {
        var lat = event.latLng.lat();
        var lng = event.latLng.lng();
        if (Ember.isPresent(lat) && Ember.isPresent(lng) && Ember.isPresent(marker)) {
          var position = new google.maps.LatLng(lat, lng);
          marker.setPosition(position);
          _this2.sendOnDrag(lat, lng);
        }
      });
    },


    labelChanged: Ember.observer('label', function () {
      Ember.run.once(this, 'setLabel');
    }),

    setLabel: function setLabel() {
      var marker = this.get('marker');
      var label = this.get('label');

      if (Ember.isPresent(marker) && Ember.isPresent(label)) {
        marker.setLabel(label);
      }
    },


    titleChanged: Ember.observer('title', function () {
      Ember.run.once(this, 'setTitle');
    }),

    setTitle: function setTitle() {
      var marker = this.get('marker');
      var title = this.get('title');

      if (Ember.isPresent(marker) && Ember.isPresent(title)) {
        marker.setTitle(title);
      }
    },
    sendOnClick: function sendOnClick() {
      var onClick = this.get('onClick');
      var mapContext = this.get('mapContext');
      var group = this.get('group');

      if (Ember.typeOf(onClick) === 'function') {
        onClick();
      } else {
        this.sendAction('onClick');
      }

      if (Ember.isPresent(group)) {
        mapContext.groupMarkerClicked(this, group);
      }
    },
    sendOnDrag: function sendOnDrag(lat, lng) {
      var onDrag = this.get('onDrag');

      if (Ember.typeOf(onDrag) === 'function') {
        onDrag(lat, lng);
      } else {
        this.sendAction('onDrag', lat, lng);
      }
    },
    closeInfowindow: function closeInfowindow() {
      var infowindow = this.get('infowindow');
      if (Ember.isPresent(infowindow)) {
        infowindow.close();
      }
    }
  });

  GMapMarkerComponent.reopenClass({
    positionalParams: ['mapContext']
  });

  exports.default = GMapMarkerComponent;
});