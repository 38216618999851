define('ember-pikaday/components/pikaday-inputless', ['exports', 'ember-pikaday/mixins/pikaday', 'ember-pikaday/templates/pikaday-inputless'], function (exports, _pikaday, _pikadayInputless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_pikaday.default, {
    layout: _pikadayInputless.default,

    didInsertElement: function didInsertElement() {
      this.set('field', this.element.querySelector('.ember-pikaday-input'));
      this.set('pikadayContainer', this.element.querySelector('.ember-pikaday-container'));
      this.setupPikaday();
    },
    onPikadayOpen: function onPikadayOpen() {},
    onPikadayClose: function onPikadayClose() {}
  });
});