define('ember-mobiledoc-editor/components/tether-to-selection/component', ['exports', 'ember-mobiledoc-editor/components/tether-to-selection/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DIALOG_MARGIN = 16;

  function isOutOfBounds(rect, boundingRect) {
    var result = {
      left: rect.left < boundingRect.left,
      right: rect.right > boundingRect.right,
      top: rect.top < boundingRect.top,
      bottom: rect.bottom > boundingRect.bottom
    };
    return result.left || result.right || result.top || result.bottom ? result : false;
  }

  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['mobiledoc-selection-tether'],
    attributeBindings: ['style'],
    dialogAnchor: null,

    style: Ember.computed('dialogAnchor', function () {
      var dialogAnchor = this.get('dialogAnchor');
      return Ember.String.htmlSafe('position: fixed; left: ' + dialogAnchor.left + 'px; bottom: ' + dialogAnchor.bottom + 'px;');
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var selection = window.getSelection();
      var range = selection && selection.rangeCount && selection.getRangeAt(0);

      (false && !(!!range) && Ember.assert('Should not render {{#tether-to-selection}} when there is no selection', !!range));


      if (range) {
        var rangeRect = range.getBoundingClientRect();
        // Fallback on the range’s parent container if no anchor position was found:
        if (!rangeRect.left && !rangeRect.top) {
          rangeRect = range.startContainer.getBoundingClientRect();
        }

        var dialogAnchor = { left: rangeRect.left, bottom: window.innerHeight - rangeRect.top };
        this.set('dialogAnchor', dialogAnchor);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.schedule('afterRender', function () {
        var boundingRect = { left: DIALOG_MARGIN, right: window.innerWidth - DIALOG_MARGIN, top: DIALOG_MARGIN, bottom: window.innerHeight - DIALOG_MARGIN };
        var dialogRect = _this.element.getBoundingClientRect();
        var isOffscreen = isOutOfBounds(dialogRect, boundingRect);
        if (isOffscreen) {
          var dialogAnchor = _this.get('dialogAnchor');
          _this.$().css({
            position: 'fixed',
            left: isOffscreen.left ? DIALOG_MARGIN : isOffscreen.right ? 'auto' : dialogAnchor.left,
            right: isOffscreen.right ? DIALOG_MARGIN : 'auto',
            top: isOffscreen.top ? DIALOG_MARGIN : 'auto',
            bottom: isOffscreen.bottom ? DIALOG_MARGIN : isOffscreen.top ? 'auto' : dialogAnchor.bottom
          });
        }
      });
    }
  });
});