define("ember-mobiledoc-editor/components/mobiledoc-link-prompt/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SbfnXgzX",
    "block": "{\"symbols\":[\"@on-submit\",\"@on-cancel\"],\"statements\":[[7,\"form\",true],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"value\"],[[24,[\"href\"]]]]],false],[0,\"\\n  \"],[7,\"button\",false],[12,\"type\",\"submit\"],[3,\"action\",[[23,0,[]],[23,1,[]],[24,[\"href\"]]]],[8],[0,\"Link\"],[9],[0,\"\\n  \"],[7,\"button\",false],[3,\"action\",[[23,0,[]],[23,2,[]]]],[8],[0,\"Cancel\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-mobiledoc-editor/components/mobiledoc-link-prompt/template.hbs"
    }
  });

  _exports.default = _default;
});