define('mobiledoc-dom-renderer/cards/image', ['exports', 'mobiledoc-dom-renderer/utils/render-type'], function (exports, _mobiledocDomRendererUtilsRenderType) {
  'use strict';

  exports['default'] = {
    name: 'image',
    type: _mobiledocDomRendererUtilsRenderType['default'],
    render: function render(_ref) {
      var payload = _ref.payload;
      var dom = _ref.env.dom;

      var img = dom.createElement('img');
      img.src = payload.src;
      return img;
    }
  };
});