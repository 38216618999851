define("ember-cli-notifications/templates/components/notification-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y2jEQeUb",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[4,\"each\",[[23,0,[\"notifications\",\"content\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"notification-message\",null,[[\"notification\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-notifications/templates/components/notification-container.hbs"
    }
  });

  _exports.default = _default;
});