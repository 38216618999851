define('ember-g-map/components/g-map-route-waypoint', ['exports', 'ember-g-map/templates/components/g-map-route-waypoint', 'ember-g-map/components/g-map-route'], function (exports, _gMapRouteWaypoint, _gMapRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var GMapRouteWaypointComponent = Ember.Component.extend({
    layout: _gMapRouteWaypoint.default,
    classNames: ['g-map-route-waypoint'],

    map: Ember.computed.alias('routeContext.map'),

    init: function init() {
      this._super.apply(this, arguments);
      if (Ember.isEmpty(this.stopover)) {
        this.stopover = true;
      }

      var routeContext = this.get('routeContext');
      (false && !(routeContext instanceof _gMapRoute.default) && Ember.assert('Must be inside {{#g-map-route}} component with routeContext set', routeContext instanceof _gMapRoute.default));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.updateWaypoint();
    },
    willDestroyElement: function willDestroyElement() {
      this.get('routeContext').unregisterWaypoint(this);
    },


    coordsChanged: Ember.observer('lat', 'lng', function () {
      Ember.run.once(this, 'updateWaypoint');
    }),

    updateWaypoint: function updateWaypoint() {
      var _getProperties = this.getProperties(['lat', 'lng']),
          lat = _getProperties.lat,
          lng = _getProperties.lng;

      if (Ember.isPresent(lat) && Ember.isPresent(lng) && typeof FastBoot === 'undefined' && typeof google !== 'undefined') {
        var location = new google.maps.LatLng(lat, lng);
        this.set('waypoint', {
          location: location,
          stopover: this.get('stopover')
        });
      }
    },


    waypointWasSet: Ember.observer('waypoint', function () {
      Ember.run.once(this, 'updateRoute');
    }),

    updateRoute: function updateRoute() {
      var routeContext = this.get('routeContext');
      var waypoint = this.get('waypoint');

      if (Ember.isPresent(waypoint) && Ember.isPresent(routeContext)) {
        routeContext.registerWaypoint(this);
      }
    }
  });

  GMapRouteWaypointComponent.reopenClass({
    positionalParams: ['routeContext']
  });

  exports.default = GMapRouteWaypointComponent;
});