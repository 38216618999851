define('ember-mobiledoc-dom-renderer/utils/document', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getDocument = getDocument;
  var getOwner = Ember.getOwner;


  // Private Ember API usage. Get the dom implementation used by the current
  // renderer, be it native browser DOM or Fastboot SimpleDOM
  function getDocument(context) {
    var container = getOwner ? getOwner(context) : context.container;
    var documentService = container.lookup('service:-document');

    if (documentService) {
      return documentService;
    }

    var renderer = container.lookup('renderer:-dom');

    if (renderer._dom && renderer._dom.document) {
      // pre Ember 2.6
      return renderer._dom.document;
    } else {
      throw new Error('ember-mobiledoc-dom-renderer could not get DOM');
    }
  }
});