define("ember-g-map/templates/components/g-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ClzPMsRx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"g-map-canvas\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"g-map-declarations\"],[8],[0,\"\\n  \"],[14,1,[[23,0,[]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-g-map/templates/components/g-map.hbs"
    }
  });

  _exports.default = _default;
});