define('ember-cli-tailwind/utils/classes-for-module-style', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (moduleStyle) {
    return moduleStyles[moduleStyle.module](moduleStyle);
  };

  var moduleStyles = {
    'border-radius': function borderRadius(moduleStyle) {
      var name = moduleStyle.name;
      var postfix = name === 'default' ? '' : '-' + name;

      return ['rounded' + postfix, 'rounded-t' + postfix, 'rounded-r' + postfix, 'rounded-b' + postfix, 'rounded-l' + postfix, 'rounded-tl' + postfix, 'rounded-tr' + postfix, 'rounded-br' + postfix, 'rounded-bl' + postfix];
    },
    'border-widths': function borderWidths(moduleStyle) {
      var name = moduleStyle.name;
      var postfix = name === 'default' ? '' : '-' + name;

      return ['border' + postfix, 'border-t' + postfix, 'border-r' + postfix, 'border-b' + postfix, 'border-l' + postfix];
    },
    'colors': function colors(moduleStyle) {
      var name = moduleStyle.name;

      return ['text-' + name, 'bg-' + name, 'border-' + name];
    },
    'font-weights': function fontWeights(moduleStyle) {
      var name = moduleStyle.name;

      return ['font-' + name];
    },
    'height': function height(moduleStyle) {
      var name = moduleStyle.name;

      return ['h-' + name];
    },
    'letter-spacing': function letterSpacing(moduleStyle) {
      var name = moduleStyle.name;

      return ['tracking-' + name];
    },
    'line-height': function lineHeight(moduleStyle) {
      var name = moduleStyle.name;

      return ['leading-' + name];
    },
    'margin': function margin(moduleStyle) {
      var name = moduleStyle.name;

      return ['m-' + name, 'mt-' + name, 'mr-' + name, 'mb-' + name, 'ml-' + name, 'mx-' + name, 'my-' + name];
    },
    'max-height': function maxHeight(moduleStyle) {
      var name = moduleStyle.name;

      return ['max-h-' + name];
    },
    'max-width': function maxWidth(moduleStyle) {
      var name = moduleStyle.name;

      return ['max-w-' + name];
    },
    'min-height': function minHeight(moduleStyle) {
      var name = moduleStyle.name;

      return ['min-h-' + name];
    },
    'min-width': function minWidth(moduleStyle) {
      var name = moduleStyle.name;

      return ['min-w-' + name];
    },
    'negative-margin': function negativeMargin(moduleStyle) {
      var name = moduleStyle.name;

      return ['-m-' + name, '-mt-' + name, '-mr-' + name, '-mb-' + name, '-ml-' + name, '-mx-' + name, '-my-' + name];
    },
    'opacity': function opacity(moduleStyle) {
      var name = moduleStyle.name;

      return ['opacity-' + name];
    },
    'padding': function padding(moduleStyle) {
      var name = moduleStyle.name;

      return ['p-' + name, 'pt-' + name, 'pr-' + name, 'pb-' + name, 'pl-' + name, 'px-' + name, 'py-' + name];
    },
    'shadows': function shadows(moduleStyle) {
      var name = moduleStyle.name;
      var postfix = name === 'default' ? '' : '-' + name;

      return ['shadow' + postfix];
    },
    'svg-fill': function svgFill(moduleStyle) {
      var name = moduleStyle.name;

      return ['fill-' + name];
    },
    'svg-stroke': function svgStroke(moduleStyle) {
      var name = moduleStyle.name;

      return ['stroke-' + name];
    },
    'text-sizes': function textSizes(moduleStyle) {
      var name = moduleStyle.name;

      return ['text-' + name];
    },
    'width': function width(moduleStyle) {
      var name = moduleStyle.name;

      return ['w-' + name];
    },
    'z-index': function zIndex(moduleStyle) {
      var name = moduleStyle.name;

      return ['z-' + name];
    }
  };
});