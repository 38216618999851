define('ember-inputmask/components/date-input', ['exports', 'ember-inputmask/components/input-mask'], function (exports, _inputMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _inputMask.default.extend({
    oldComponent: '{{date-input}}',
    newComponent: '{{one-way-date-mask}}',
    mask: 'date'
  });
});