define('ember-inputmask/components/one-way-input-mask', ['exports', 'ember-inputmask/utils/compare-objects', 'inputmask'], function (exports, _compareObjects, _inputmask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.DEFAULT_NON_BOUND_PROPS = undefined;


  var DEFAULT_OPTIONS = {
    rightAlign: false
  };

  var DEFAULT_NON_BOUND_PROPS = exports.DEFAULT_NON_BOUND_PROPS = ['keyEvents', 'class', 'classNames', 'positionalParamValue', 'update', 'mask', 'options'];

  /**
   * Displays an input with the specified mask applied to it
   * using Inputmask library. Follows Data-down actions up pattern
   *
   * @param {string} value The unmasked value to display in the input
   * @param {action} update The function to perform when the value changes. Will be passed the
   * unmasked value and the masked values
   * @param {string} mask The mask to use on the input
   * @param {object} options The options to pass into the Inputmask library
   */
  var OneWayInputMask = Ember.Component.extend({
    tagName: 'input',

    /**
     * Set the `_value` to be whatever the `element.value` is
     */
    attributeBindings: ['type', '_value:value'],

    // This is where we blacklist all the attributes that should not be bound
    NON_ATTRIBUTE_BOUND_PROPS: DEFAULT_NON_BOUND_PROPS,

    type: 'text',

    /**
     * mask - Pass in the `mask` string to set it on the element
     *
     * @public
     */
    mask: '',
    _oldMask: '',

    /**
     * options - Options accepted by the Inputmask library
     */
    options: null,
    _options: null, // Internal options so external attribute doesnt clobber it
    _oldOptions: null,

    keyEvents: {
      '13': 'onenter',
      '27': 'onescape'
    },

    /**
     * Setup _value to be a positional param or the passed param if that is not defined
     *
     * @private
     */
    _value: Ember.computed('positionalParamValue', 'value', {
      get: function get() {
        var value = Ember.get(this, 'positionalParamValue');
        if (value === undefined) {
          value = Ember.get(this, 'value');
        }

        return value;
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);

      // Give the mask some default options that can be overridden
      var options = Ember.get(this, 'options');
      Ember.set(this, '_options', Object.assign({}, DEFAULT_OPTIONS, options));

      // We want any attribute that is not explicitally blacklisted to be bound that way we don't
      // have to whitelist every single html attribute that an `input` can have. Borrowed from
      // https://github.com/DockYard/ember-one-way-controls/blob/master/addon/-private/dynamic-attribute-bindings.js
      var newAttributeBindings = [];
      for (var key in this.attrs) {
        if (Ember.get(this, 'NON_ATTRIBUTE_BOUND_PROPS').indexOf(key) === -1 && Ember.get(this, 'attributeBindings').indexOf(key) === -1) {
          newAttributeBindings.push(key);
        }
      }

      Ember.set(this, 'attributeBindings', this.attributeBindings.concat(newAttributeBindings));
    },
    didInsertElement: function didInsertElement() {
      this._setupMask();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var mask = Ember.get(this, 'mask');
      var oldMask = Ember.get(this, '_oldMask');
      var didMaskChange = mask !== oldMask;
      var options = Ember.get(this, 'options');
      var oldOptions = Ember.get(this, '_oldOptions');
      var didOptionsChange = (0, _compareObjects.areDifferent)(options, oldOptions);

      if (didOptionsChange) {
        // Override external options on top of internal options
        Ember.set(this, '_options', Object.assign({}, Ember.get(this, '_options'), options));
      }

      // We want to reapply the mask if it has changed
      if (didMaskChange || didOptionsChange) {
        Ember.set(this, '_oldMask', mask);
        Ember.set(this, '_oldOptions', options);
        this._changeMask();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._destroyMask();
    },
    update: function update() {},
    _changeEventListener: function _changeEventListener() {},
    keyUp: function keyUp(event) {
      var method = Ember.get(this, 'keyEvents.' + event.keyCode);
      if (method) {
        Ember.get(this, method)(event.target.value);
      }
    },
    sendUpdate: function sendUpdate(unmaskedValue, value) {
      Ember.get(this, 'update')(unmaskedValue, value);
    },
    _processNewValue: function _processNewValue(value) {
      var _this = this;

      var cursorStart = this.element.selectionStart;
      var cursorEnd = this.element.selectionEnd;
      var unmaskedValue = this._getUnmaskedValue();
      var oldUnmaskedValue = Ember.get(this, '_value');
      var options = Ember.get(this, '_options');

      // We only want to make changes if something is different so we don't cause infinite loops or
      // double renders.
      // We want to make sure that that values we compare are going to come out the same through
      // the masking algorithm, to ensure that we only call `update` if the values are actually different
      // (e.g. '1234.' will be masked as '1234' and so when `update` is called and passed back
      // into the component the decimal will be removed, we don't want this)
      if (_inputmask.default.format(String(oldUnmaskedValue), options) !== _inputmask.default.format(unmaskedValue, options)) {
        this.sendUpdate(unmaskedValue, value);

        // When the value is updated, and then sent back down the cursor moves to the end of the field.
        // We therefore need to put it back to where the user was typing so they don't get janked around
        Ember.run.schedule('afterRender', function () {
          _this.element.setSelectionRange(cursorStart, cursorEnd);
        });
      }
    },
    _setupMask: function _setupMask() {
      var _this2 = this;

      var mask = Ember.get(this, 'mask'),
          options = Ember.get(this, '_options');
      var inputmask = new _inputmask.default(mask, options);
      inputmask.mask(this.element);

      // We need to setup a manual event listener for the change event instead of using the Ember
      // Component event methods, because the Inputmask events don't play nice with the Component
      // ones. Similar issue happens in React.js as well
      // https://github.com/RobinHerbots/Inputmask/issues/1377
      var eventListener = function eventListener(event) {
        return _this2._processNewValue(event.target.value);
      };
      Ember.set(this, '_changeEventListener', eventListener);
      this.element.addEventListener('input', eventListener);
    },
    _getUnmaskedValue: function _getUnmaskedValue() {
      return this.element.inputmask.unmaskedvalue();
    },
    _changeMask: function _changeMask() {
      if (this.element && this.element.inputmask) {
        this._destroyMask();
        this._setupMask();
      }
    },
    _destroyMask: function _destroyMask() {
      this.element.removeEventListener('input', this._changeEventListener);
      this.element.inputmask.remove();
    }
  });

  OneWayInputMask.reopenClass({
    positionalParams: ['positionalParamValue']
  });

  exports.default = OneWayInputMask;
});