define('ember-statecharts/utils/statechart', ['exports', 'xstate'], function (exports, _xstate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Statechart = function () {
    function Statechart(config, options) {
      _classCallCheck(this, Statechart);

      this.machine = (0, _xstate.Machine)(config, options);

      this.didChangeState = config.didChangeState || function () {};
      this.context = config.context;

      this.currentState = this.machine.initialState;
    }

    _createClass(Statechart, [{
      key: 'send',
      value: function send(eventName) {
        var _this = this;

        var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var newState = this.machine.transition(this.currentState, { type: eventName, data: data }, this.context);

        Ember.set(this, 'currentState', newState);

        var actions = newState.actions;


        this.didChangeState(newState);

        var _actions = actions.map(this._functionForAction.bind(this));

        var chain = _actions.reduce(function (acc, action) {
          return acc.then(function () {
            return action(data, _this.context);
          });
        }, Ember.RSVP.resolve());

        return chain;
      }
    }, {
      key: '_functionForAction',
      value: function _functionForAction(action) {
        if (typeof action === 'string') {
          return this.context && this.context[action] && this.context[action].bind(this.context) || function () {};
        }

        return action;
      }
    }]);

    return Statechart;
  }();

  exports.default = Statechart;
});