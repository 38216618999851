define('ember-mobiledoc-editor/version', ['exports', 'mobiledoc-kit/version'], function (exports, _version) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var EDITOR_VERSION = '0.4.3';
  var VERSION = {
    EDITOR_VERSION: EDITOR_VERSION,
    MOBILEDOC_KIT_VERSION: _version.default
  };

  exports.default = VERSION;
});