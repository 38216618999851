define('ember-mobiledoc-editor/utils/titleize', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (string) {
    return Ember.String.capitalize(Ember.String.camelize(string));
  };
});