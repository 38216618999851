define('mobiledoc-dom-renderer/renderer-factory', ['exports', 'mobiledoc-dom-renderer/renderers/0-2', 'mobiledoc-dom-renderer/renderers/0-3', 'mobiledoc-dom-renderer/utils/render-type'], function (exports, _mobiledocDomRendererRenderers02, _mobiledocDomRendererRenderers03, _mobiledocDomRendererUtilsRenderType) {
  'use strict';

  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  /**
   * runtime DOM renderer
   * renders a mobiledoc to DOM
   *
   * input: mobiledoc
   * output: DOM
   */

  function validateCards(cards) {
    if (!Array.isArray(cards)) {
      throw new Error('`cards` must be passed as an array');
    }
    for (var i = 0; i < cards.length; i++) {
      var card = cards[i];
      if (card.type !== _mobiledocDomRendererUtilsRenderType['default']) {
        throw new Error('Card "' + card.name + '" must be of type "' + _mobiledocDomRendererUtilsRenderType['default'] + '", was "' + card.type + '"');
      }
      if (!card.render) {
        throw new Error('Card "' + card.name + '" must define `render`');
      }
    }
  }

  function validateAtoms(atoms) {
    if (!Array.isArray(atoms)) {
      throw new Error('`atoms` must be passed as an array');
    }
    for (var i = 0; i < atoms.length; i++) {
      var atom = atoms[i];
      if (atom.type !== _mobiledocDomRendererUtilsRenderType['default']) {
        throw new Error('Atom "' + atom.name + '" must be type "' + _mobiledocDomRendererUtilsRenderType['default'] + '", was "' + atom.type + '"');
      }
      if (!atom.render) {
        throw new Error('Atom "' + atom.name + '" must define `render`');
      }
    }
  }

  var RendererFactory = (function () {
    function RendererFactory() {
      var _ref = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var _ref$cards = _ref.cards;
      var cards = _ref$cards === undefined ? [] : _ref$cards;
      var _ref$atoms = _ref.atoms;
      var atoms = _ref$atoms === undefined ? [] : _ref$atoms;
      var _ref$cardOptions = _ref.cardOptions;
      var cardOptions = _ref$cardOptions === undefined ? {} : _ref$cardOptions;
      var unknownCardHandler = _ref.unknownCardHandler;
      var unknownAtomHandler = _ref.unknownAtomHandler;
      var _ref$markupElementRenderer = _ref.markupElementRenderer;
      var markupElementRenderer = _ref$markupElementRenderer === undefined ? {} : _ref$markupElementRenderer;
      var _ref$sectionElementRenderer = _ref.sectionElementRenderer;
      var sectionElementRenderer = _ref$sectionElementRenderer === undefined ? {} : _ref$sectionElementRenderer;
      var dom = _ref.dom;
      var _ref$markupSanitizer = _ref.markupSanitizer;
      var markupSanitizer = _ref$markupSanitizer === undefined ? null : _ref$markupSanitizer;

      _classCallCheck(this, RendererFactory);

      validateCards(cards);
      validateAtoms(atoms);

      if (!dom) {
        if (typeof window === 'undefined') {
          throw new Error('A `dom` option must be provided to the renderer when running without window.document');
        }
        dom = window.document;
      }

      this.options = {
        cards: cards,
        atoms: atoms,
        cardOptions: cardOptions,
        unknownCardHandler: unknownCardHandler,
        unknownAtomHandler: unknownAtomHandler,
        markupElementRenderer: markupElementRenderer,
        sectionElementRenderer: sectionElementRenderer,
        dom: dom,
        markupSanitizer: markupSanitizer
      };
    }

    _createClass(RendererFactory, [{
      key: 'render',
      value: function render(mobiledoc) {
        var version = mobiledoc.version;

        switch (version) {
          case _mobiledocDomRendererRenderers02.MOBILEDOC_VERSION:
          case undefined:
          case null:
            return new _mobiledocDomRendererRenderers02['default'](mobiledoc, this.options).render();
          case _mobiledocDomRendererRenderers03.MOBILEDOC_VERSION_0_3_0:
          case _mobiledocDomRendererRenderers03.MOBILEDOC_VERSION_0_3_1:
            return new _mobiledocDomRendererRenderers03['default'](mobiledoc, this.options).render();
          default:
            throw new Error('Unexpected Mobiledoc version "' + version + '"');
        }
      }
    }]);

    return RendererFactory;
  })();

  exports['default'] = RendererFactory;
});