define('ember-g-map/components/g-map-route-address-waypoint', ['exports', 'ember-g-map/templates/components/g-map-route-address-waypoint'], function (exports, _gMapRouteAddressWaypoint) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var GMapRouteAddressWaypointComponent = Ember.Component.extend({
    layout: _gMapRouteAddressWaypoint.default,
    classNames: ['g-map-route-address-waypoint'],

    map: Ember.computed.alias('routeContext.map'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initPlacesService();
    },


    initPlacesService: Ember.observer('map', function () {
      var map = this.get('map');
      var service = this.get('placesService');

      if (Ember.isPresent(map) && Ember.isEmpty(service) && typeof FastBoot === 'undefined' && typeof google !== 'undefined') {
        service = new google.maps.places.PlacesService(map);
        this.set('placesService', service);

        this.searchLocation();
      }
    }),

    onAddressChanged: Ember.observer('address', function () {
      Ember.run.once(this, 'searchLocation');
    }),

    searchLocation: function searchLocation() {
      var _this = this;

      var service = this.get('placesService');
      var address = this.get('address');

      if (Ember.isPresent(service) && Ember.isPresent(address)) {
        var request = { query: address };

        service.textSearch(request, function (results, status) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            _this.updateLocation(results);
          }
        });
      }
    },
    updateLocation: function updateLocation(results) {
      if (!this.isDestroyed) {
        var lat = results[0].geometry.location.lat();
        var lng = results[0].geometry.location.lng();

        this.set('lat', lat);
        this.set('lng', lng);
      }
    }
  });

  GMapRouteAddressWaypointComponent.reopenClass({
    positionalParams: ['routeContext']
  });

  exports.default = GMapRouteAddressWaypointComponent;
});