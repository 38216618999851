define('ember-pikaday/helpers/pikaday', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  (false && !(true) && Ember.deprecate('Pikaday helpers from `ember-pikaday/helpers/pikaday` will be removed in the next major version; please use `async/await` helpers from `ember-pikaday/test-support`', true, {
    id: 'ember-pikaday.test-support-helpers',
    until: '3.0.0'
  }));


  var openDatepicker = function openDatepicker(element) {
    Ember.$(element).click();

    return PikadayInteractor;
  };

  var PikadayInteractor = {
    selectorForMonthSelect: '.pika-lendar:visible .pika-select-month',
    selectorForYearSelect: '.pika-lendar:visible .pika-select-year',
    selectDate: function selectDate(date) {
      var day = date.getDate();
      var month = date.getMonth();
      var year = date.getFullYear();
      var selectEvent = 'ontouchend' in document ? 'touchend' : 'mousedown';

      Ember.$(this.selectorForYearSelect).val(year);
      triggerNativeEvent(Ember.$(this.selectorForYearSelect)[0], 'change');
      Ember.$(this.selectorForMonthSelect).val(month);
      triggerNativeEvent(Ember.$(this.selectorForMonthSelect)[0], 'change');

      triggerNativeEvent(Ember.$('td[data-day="' + day + '"]:not(.is-outside-current-month) button:visible')[0], selectEvent);
    },
    selectedDay: function selectedDay() {
      return Ember.$('.pika-single td.is-selected button').html();
    },
    selectedMonth: function selectedMonth() {
      return Ember.$(this.selectorForMonthSelect + ' option:selected').val();
    },
    selectedYear: function selectedYear() {
      return Ember.$(this.selectorForYearSelect + ' option:selected').val();
    },
    minimumYear: function minimumYear() {
      return Ember.$(this.selectorForYearSelect).children().first().val();
    },
    maximumYear: function maximumYear() {
      return Ember.$(this.selectorForYearSelect).children().last().val();
    }
  };

  function triggerNativeEvent(element, eventName) {
    if (document.createEvent) {
      var event = document.createEvent('Events');
      event.initEvent(eventName, true, false);
      element.dispatchEvent(event);
    } else {
      element.fireEvent('on' + eventName);
    }
  }

  exports.openDatepicker = openDatepicker;
});