define('ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/render-utils', ['exports', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/tag-names', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/sanitization-utils'], function (exports, _tagNames, _sanitizationUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.defaultSectionElementRenderer = defaultSectionElementRenderer;
  exports.defaultMarkupElementRenderer = defaultMarkupElementRenderer;
  function defaultSectionElementRenderer(tagName, dom) {
    var element = void 0;
    if ((0, _tagNames.isMarkupSectionElementName)(tagName)) {
      element = dom.createElement(tagName);
    } else {
      element = dom.createElement('div');
      element.setAttribute('class', tagName);
    }

    return element;
  }

  function sanitizeAttribute(tagName, attrName, attrValue) {
    if (tagName === 'a' && attrName === 'href') {
      return (0, _sanitizationUtils.sanitizeHref)(attrValue);
    } else {
      return attrValue;
    }
  }

  function defaultMarkupElementRenderer(tagName, dom, attrsObj) {
    var element = dom.createElement(tagName);
    Object.keys(attrsObj).forEach(function (attrName) {
      var attrValue = attrsObj[attrName];
      attrValue = sanitizeAttribute(tagName, attrName, attrValue);
      element.setAttribute(attrName, attrValue);
    });
    return element;
  }
});