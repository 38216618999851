define("ember-g-map/templates/components/g-map-address-marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HhdImvz7",
    "block": "{\"symbols\":[\"markerContext\",\"&default\"],\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[4,\"g-map-marker\",[[24,[\"mapContext\"]]],[[\"lat\",\"lng\",\"viewport\",\"icon\",\"label\",\"group\",\"title\",\"onClick\",\"onDrag\"],[[24,[\"lat\"]],[24,[\"lng\"]],[24,[\"viewport\"]],[24,[\"icon\"]],[24,[\"label\"]],[24,[\"group\"]],[24,[\"title\"]],[24,[\"onClick\"]],[24,[\"onDrag\"]]]],{\"statements\":[[0,\"    \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"g-map-marker\",[[24,[\"mapContext\"]]],[[\"lat\",\"lng\",\"viewport\",\"icon\",\"label\",\"title\",\"group\",\"onClick\",\"onDrag\"],[[24,[\"lat\"]],[24,[\"lng\"]],[24,[\"viewport\"]],[24,[\"icon\"]],[24,[\"label\"]],[24,[\"title\"]],[24,[\"group\"]],[24,[\"onClick\"]],[24,[\"onDrag\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-g-map/templates/components/g-map-address-marker.hbs"
    }
  });

  _exports.default = _default;
});