define("ember-mobiledoc-dom-renderer/templates/components/render-mobiledoc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mwf/dScV",
    "block": "{\"symbols\":[\"atom\",\"card\"],\"statements\":[[1,[22,\"renderedMobiledoc\"],false],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"_componentCards\"]]],null,{\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[[23,2,[\"destinationElementId\"]]]],{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[23,2,[\"componentName\"]]],[[\"options\",\"payload\"],[[28,\"readonly\",[[23,2,[\"options\"]]],null],[28,\"readonly\",[[23,2,[\"payload\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null],[4,\"each\",[[24,[\"_componentAtoms\"]]],null,{\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[[23,1,[\"destinationElementId\"]]]],{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[23,1,[\"componentName\"]]],[[\"options\",\"payload\",\"value\"],[[28,\"readonly\",[[23,1,[\"options\"]]],null],[28,\"readonly\",[[23,1,[\"payload\"]]],null],[28,\"readonly\",[[23,1,[\"value\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-mobiledoc-dom-renderer/templates/components/render-mobiledoc.hbs"
    }
  });

  _exports.default = _default;
});