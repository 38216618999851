define("ember-g-map/templates/components/g-map-infowindow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PYKN+Gqe",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"h1\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[1,[22,\"message\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-g-map/templates/components/g-map-infowindow.hbs"
    }
  });

  _exports.default = _default;
});