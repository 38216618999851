define('ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/renderers/0-3', ['exports', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/dom', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/cards/image', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/render-type', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/section-types', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/tag-names', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/sanitization-utils', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/render-utils', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/marker-types'], function (exports, _dom, _image, _renderType, _sectionTypes, _tagNames, _sanitizationUtils, _renderUtils, _markerTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.MOBILEDOC_VERSION = exports.MOBILEDOC_VERSION_0_3_1 = exports.MOBILEDOC_VERSION_0_3_0 = undefined;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var MOBILEDOC_VERSION_0_3_0 = exports.MOBILEDOC_VERSION_0_3_0 = '0.3.0';
  var MOBILEDOC_VERSION_0_3_1 = exports.MOBILEDOC_VERSION_0_3_1 = '0.3.1';
  var MOBILEDOC_VERSION = exports.MOBILEDOC_VERSION = MOBILEDOC_VERSION_0_3_0;

  var IMAGE_SECTION_TAG_NAME = 'img';

  function validateVersion(version) {
    switch (version) {
      case MOBILEDOC_VERSION_0_3_0:
      case MOBILEDOC_VERSION_0_3_1:
        return;
      default:
        throw new Error('Unexpected Mobiledoc version "' + version + '"');
    }
  }

  var Renderer = function () {
    function Renderer(mobiledoc, state) {
      var _this = this;

      _classCallCheck(this, Renderer);

      var cards = state.cards,
          cardOptions = state.cardOptions,
          atoms = state.atoms,
          unknownCardHandler = state.unknownCardHandler,
          unknownAtomHandler = state.unknownAtomHandler,
          markupElementRenderer = state.markupElementRenderer,
          sectionElementRenderer = state.sectionElementRenderer,
          dom = state.dom;
      var version = mobiledoc.version,
          sections = mobiledoc.sections,
          atomTypes = mobiledoc.atoms,
          cardTypes = mobiledoc.cards,
          markerTypes = mobiledoc.markups;

      validateVersion(version);

      this.dom = dom;
      this.root = this.dom.createDocumentFragment();
      this.sections = sections;
      this.atomTypes = atomTypes;
      this.cardTypes = cardTypes;
      this.markerTypes = markerTypes;
      this.cards = cards;
      this.atoms = atoms;
      this.cardOptions = cardOptions;
      this.unknownCardHandler = unknownCardHandler || this._defaultUnknownCardHandler;
      this.unknownAtomHandler = unknownAtomHandler || this._defaultUnknownAtomHandler;

      this.sectionElementRenderer = {
        '__default__': _renderUtils.defaultSectionElementRenderer
      };
      Object.keys(sectionElementRenderer).forEach(function (key) {
        _this.sectionElementRenderer[key.toLowerCase()] = sectionElementRenderer[key];
      });

      this.markupElementRenderer = {
        '__default__': _renderUtils.defaultMarkupElementRenderer
      };
      Object.keys(markupElementRenderer).forEach(function (key) {
        _this.markupElementRenderer[key.toLowerCase()] = markupElementRenderer[key];
      });

      this._renderCallbacks = [];
      this._teardownCallbacks = [];
    }

    _createClass(Renderer, [{
      key: 'render',
      value: function render() {
        var _this2 = this;

        this.sections.forEach(function (section) {
          var rendered = _this2.renderSection(section);
          if (rendered) {
            _this2.root.appendChild(rendered);
          }
        });
        for (var i = 0; i < this._renderCallbacks.length; i++) {
          this._renderCallbacks[i]();
        }
        // maintain a reference to child nodes so they can be cleaned up later by teardown
        this._renderedChildNodes = Array.prototype.slice.call(this.root.childNodes);
        return { result: this.root, teardown: function teardown() {
            return _this2.teardown();
          } };
      }
    }, {
      key: 'teardown',
      value: function teardown() {
        for (var i = 0; i < this._teardownCallbacks.length; i++) {
          this._teardownCallbacks[i]();
        }
        for (var _i = 0; _i < this._renderedChildNodes.length; _i++) {
          var node = this._renderedChildNodes[_i];
          if (node.parentNode) {
            node.parentNode.removeChild(node);
          }
        }
      }
    }, {
      key: 'renderSection',
      value: function renderSection(section) {
        var _section = _slicedToArray(section, 1),
            type = _section[0];

        switch (type) {
          case _sectionTypes.MARKUP_SECTION_TYPE:
            return this.renderMarkupSection(section);
          case _sectionTypes.IMAGE_SECTION_TYPE:
            return this.renderImageSection(section);
          case _sectionTypes.LIST_SECTION_TYPE:
            return this.renderListSection(section);
          case _sectionTypes.CARD_SECTION_TYPE:
            return this.renderCardSection(section);
          default:
            throw new Error('Cannot render mobiledoc section of type "' + type + '"');
        }
      }
    }, {
      key: 'renderMarkersOnElement',
      value: function renderMarkersOnElement(element, markers) {
        var elements = [element];
        var currentElement = element;

        var pushElement = function pushElement(openedElement) {
          currentElement.appendChild(openedElement);
          elements.push(openedElement);
          currentElement = openedElement;
        };

        for (var i = 0, l = markers.length; i < l; i++) {
          var marker = markers[i];

          var _marker = _slicedToArray(marker, 4),
              type = _marker[0],
              openTypes = _marker[1],
              closeCount = _marker[2],
              value = _marker[3];

          for (var j = 0, m = openTypes.length; j < m; j++) {
            var markerType = this.markerTypes[openTypes[j]];

            var _markerType = _slicedToArray(markerType, 2),
                tagName = _markerType[0],
                _markerType$ = _markerType[1],
                attrs = _markerType$ === undefined ? [] : _markerType$;

            if ((0, _tagNames.isValidMarkerType)(tagName)) {
              pushElement(this.renderMarkupElement(tagName, attrs));
            } else {
              closeCount--;
            }
          }

          switch (type) {
            case _markerTypes.MARKUP_MARKER_TYPE:
              currentElement.appendChild((0, _dom.createTextNode)(this.dom, value));
              break;
            case _markerTypes.ATOM_MARKER_TYPE:
              currentElement.appendChild(this._renderAtom(value));
              break;
            default:
              throw new Error('Unknown markup type (' + type + ')');
          }

          for (var _j = 0, _m = closeCount; _j < _m; _j++) {
            elements.pop();
            currentElement = elements[elements.length - 1];
          }
        }
      }
    }, {
      key: 'renderMarkupElement',
      value: function renderMarkupElement(tagName, attrs) {
        tagName = tagName.toLowerCase();
        attrs = (0, _sanitizationUtils.reduceAttributes)(attrs);

        var renderer = this.markupElementRendererFor(tagName);
        return renderer(tagName, this.dom, attrs);
      }
    }, {
      key: 'markupElementRendererFor',
      value: function markupElementRendererFor(tagName) {
        return this.markupElementRenderer[tagName] || this.markupElementRenderer.__default__;
      }
    }, {
      key: 'renderListItem',
      value: function renderListItem(markers) {
        var element = this.dom.createElement('li');
        this.renderMarkersOnElement(element, markers);
        return element;
      }
    }, {
      key: 'renderListSection',
      value: function renderListSection(_ref) {
        var _this3 = this;

        var _ref2 = _slicedToArray(_ref, 3),
            type = _ref2[0],
            tagName = _ref2[1],
            listItems = _ref2[2];

        if (!(0, _tagNames.isValidSectionTagName)(tagName, _sectionTypes.LIST_SECTION_TYPE)) {
          return;
        }
        var element = this.dom.createElement(tagName);
        listItems.forEach(function (li) {
          element.appendChild(_this3.renderListItem(li));
        });
        return element;
      }
    }, {
      key: 'renderImageSection',
      value: function renderImageSection(_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
            type = _ref4[0],
            src = _ref4[1];

        var element = this.dom.createElement(IMAGE_SECTION_TAG_NAME);
        element.src = src;
        return element;
      }
    }, {
      key: 'findCard',
      value: function findCard(name) {
        for (var i = 0; i < this.cards.length; i++) {
          if (this.cards[i].name === name) {
            return this.cards[i];
          }
        }
        if (name === _image.default.name) {
          return _image.default;
        }
        return this._createUnknownCard(name);
      }
    }, {
      key: '_findCardByIndex',
      value: function _findCardByIndex(index) {
        var cardType = this.cardTypes[index];
        if (!cardType) {
          throw new Error('No card definition found at index ' + index);
        }

        var _cardType = _slicedToArray(cardType, 2),
            name = _cardType[0],
            payload = _cardType[1];

        var card = this.findCard(name);

        return {
          card: card,
          payload: payload
        };
      }
    }, {
      key: '_createUnknownCard',
      value: function _createUnknownCard(name) {
        return {
          name: name,
          type: _renderType.default,
          render: this.unknownCardHandler
        };
      }
    }, {
      key: '_createCardArgument',
      value: function _createCardArgument(card) {
        var _this4 = this;

        var payload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var env = {
          name: card.name,
          isInEditor: false,
          dom: this.dom,
          didRender: function didRender(callback) {
            return _this4._registerRenderCallback(callback);
          },
          onTeardown: function onTeardown(callback) {
            return _this4._registerTeardownCallback(callback);
          }
        };

        var options = this.cardOptions;

        return { env: env, options: options, payload: payload };
      }
    }, {
      key: '_registerTeardownCallback',
      value: function _registerTeardownCallback(callback) {
        this._teardownCallbacks.push(callback);
      }
    }, {
      key: '_registerRenderCallback',
      value: function _registerRenderCallback(callback) {
        this._renderCallbacks.push(callback);
      }
    }, {
      key: 'renderCardSection',
      value: function renderCardSection(_ref5) {
        var _ref6 = _slicedToArray(_ref5, 2),
            type = _ref6[0],
            index = _ref6[1];

        var _findCardByIndex2 = this._findCardByIndex(index),
            card = _findCardByIndex2.card,
            payload = _findCardByIndex2.payload;

        var cardArg = this._createCardArgument(card, payload);
        var rendered = card.render(cardArg);

        this._validateCardRender(rendered, card.name);

        return rendered;
      }
    }, {
      key: '_validateCardRender',
      value: function _validateCardRender(rendered, cardName) {
        if (!rendered) {
          return;
        }

        if ((typeof rendered === 'undefined' ? 'undefined' : _typeof(rendered)) !== 'object') {
          throw new Error('Card "' + cardName + '" must render ' + _renderType.default + ', but result was "' + rendered + '"');
        }
      }
    }, {
      key: 'findAtom',
      value: function findAtom(name) {
        for (var i = 0; i < this.atoms.length; i++) {
          if (this.atoms[i].name === name) {
            return this.atoms[i];
          }
        }
        return this._createUnknownAtom(name);
      }
    }, {
      key: '_createUnknownAtom',
      value: function _createUnknownAtom(name) {
        return {
          name: name,
          type: _renderType.default,
          render: this.unknownAtomHandler
        };
      }
    }, {
      key: '_createAtomArgument',
      value: function _createAtomArgument(atom, value, payload) {
        var _this5 = this;

        var env = {
          name: atom.name,
          isInEditor: false,
          dom: this.dom,
          onTeardown: function onTeardown(callback) {
            return _this5._registerTeardownCallback(callback);
          }
        };

        var options = this.cardOptions;

        return { env: env, options: options, value: value, payload: payload };
      }
    }, {
      key: '_validateAtomRender',
      value: function _validateAtomRender(rendered, atomName) {
        if (!rendered) {
          return;
        }

        if ((typeof rendered === 'undefined' ? 'undefined' : _typeof(rendered)) !== 'object') {
          throw new Error('Atom "' + atomName + '" must render ' + _renderType.default + ', but result was "' + rendered + '"');
        }
      }
    }, {
      key: '_findAtomByIndex',
      value: function _findAtomByIndex(index) {
        var atomType = this.atomTypes[index];
        if (!atomType) {
          throw new Error('No atom definition found at index ' + index);
        }

        var _atomType = _slicedToArray(atomType, 3),
            name = _atomType[0],
            value = _atomType[1],
            payload = _atomType[2];

        var atom = this.findAtom(name);

        return {
          atom: atom,
          value: value,
          payload: payload
        };
      }
    }, {
      key: '_renderAtom',
      value: function _renderAtom(index) {
        var _findAtomByIndex2 = this._findAtomByIndex(index),
            atom = _findAtomByIndex2.atom,
            value = _findAtomByIndex2.value,
            payload = _findAtomByIndex2.payload;

        var atomArg = this._createAtomArgument(atom, value, payload);
        var rendered = atom.render(atomArg);

        this._validateAtomRender(rendered, atom.name);

        return rendered || (0, _dom.createTextNode)(this.dom, '');
      }
    }, {
      key: 'renderMarkupSection',
      value: function renderMarkupSection(_ref7) {
        var _ref8 = _slicedToArray(_ref7, 3),
            type = _ref8[0],
            tagName = _ref8[1],
            markers = _ref8[2];

        tagName = tagName.toLowerCase();
        if (!(0, _tagNames.isValidSectionTagName)(tagName, _sectionTypes.MARKUP_SECTION_TYPE)) {
          return;
        }

        var renderer = this.sectionElementRendererFor(tagName);
        var element = renderer(tagName, this.dom);

        this.renderMarkersOnElement(element, markers);
        return element;
      }
    }, {
      key: 'sectionElementRendererFor',
      value: function sectionElementRendererFor(tagName) {
        return this.sectionElementRenderer[tagName] || this.sectionElementRenderer.__default__;
      }
    }, {
      key: '_defaultUnknownCardHandler',
      get: function get() {
        return function (_ref9) {
          var name = _ref9.env.name;

          throw new Error('Card "' + name + '" not found but no unknownCardHandler was registered');
        };
      }
    }, {
      key: '_defaultUnknownAtomHandler',
      get: function get() {
        return function (_ref10) {
          var name = _ref10.env.name;

          throw new Error('Atom "' + name + '" not found but no unknownAtomHandler was registered');
        };
      }
    }]);

    return Renderer;
  }();

  exports.default = Renderer;
});