define('mobiledoc-dom-renderer', ['exports', 'mobiledoc-dom-renderer/renderer-factory', 'mobiledoc-dom-renderer/utils/render-type'], function (exports, _mobiledocDomRendererRendererFactory, _mobiledocDomRendererUtilsRenderType) {
  'use strict';

  exports.registerGlobal = registerGlobal;
  exports.RENDER_TYPE = _mobiledocDomRendererUtilsRenderType['default'];

  function registerGlobal(window) {
    window.MobiledocDOMRenderer = _mobiledocDomRendererRendererFactory['default'];
  }

  exports['default'] = _mobiledocDomRendererRendererFactory['default'];
});