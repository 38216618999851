define("ember-buffered-proxy/helpers", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.aliasMethod = aliasMethod;
  exports.empty = empty;
  var hasOwnProp = Object.prototype.hasOwnProperty;

  function aliasMethod(methodName) {
    return function () {
      return this[methodName].apply(this, arguments);
    };
  }

  function empty(obj) {
    var key;
    for (key in obj) {
      if (!hasOwnProp.call(obj, key)) {
        continue;
      }
      return false;
    }
    return true;
  }
});