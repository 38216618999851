define('ember-buffered-proxy/mixin', ['exports', 'ember-buffered-proxy/helpers'], function (exports, _helpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      isArray = Ember.isArray,
      computed = Ember.computed,
      getProperties = Ember.getProperties,
      defineProperty = Ember.defineProperty,
      meta = Ember.meta,
      notifyPropertyChange = Ember.notifyPropertyChange;


  var keys = Object.keys || Ember.keys;
  var create = Object.create || Ember.create;
  var hasOwnProp = Object.prototype.hasOwnProperty;

  exports.default = Ember.Mixin.create({
    buffer: null,
    hasBufferedChanges: false,

    hasChanges: computed.readOnly('hasBufferedChanges'),
    applyChanges: (0, _helpers.aliasMethod)('applyBufferedChanges'),
    discardChanges: (0, _helpers.aliasMethod)('discardBufferedChanges'),

    init: function init() {
      this.initializeBuffer();
      set(this, 'hasBufferedChanges', false);
      this._super.apply(this, arguments);
    },
    initializeBuffer: function initializeBuffer(onlyTheseKeys) {
      var _this = this;

      if (isArray(onlyTheseKeys) && !(0, _helpers.empty)(onlyTheseKeys)) {
        onlyTheseKeys.forEach(function (key) {
          return delete _this.buffer[key];
        });
      } else {
        set(this, 'buffer', create(null));
      }
    },
    unknownProperty: function unknownProperty(key) {
      var buffer = get(this, 'buffer');

      return hasOwnProp.call(buffer, key) ? buffer[key] : this._super(key);
    },
    setUnknownProperty: function setUnknownProperty(key, value) {
      var m = meta(this);

      if (m.proto === this || m.isInitializing && m.isInitializing()) {
        // if marked as prototype or object is initializing then just
        // defineProperty rather than delegate
        defineProperty(this, key, null, value);
        return value;
      }

      var _getProperties = getProperties(this, ['buffer', 'content']),
          buffer = _getProperties.buffer,
          content = _getProperties.content;

      var current = void 0;
      var previous = void 0;

      if (content != null) {
        current = get(content, key);
      }

      previous = hasOwnProp.call(buffer, key) ? buffer[key] : current;

      if (previous === value) {
        return;
      }

      if (current === value) {
        delete buffer[key];
        if ((0, _helpers.empty)(buffer)) {
          set(this, 'hasBufferedChanges', false);
        }
      } else {
        buffer[key] = value;
        set(this, 'hasBufferedChanges', true);
      }

      notifyPropertyChange(this, key);

      return value;
    },
    applyBufferedChanges: function applyBufferedChanges(onlyTheseKeys) {
      var _getProperties2 = getProperties(this, ['buffer', 'content']),
          buffer = _getProperties2.buffer,
          content = _getProperties2.content;

      keys(buffer).forEach(function (key) {
        if (isArray(onlyTheseKeys) && onlyTheseKeys.indexOf(key) === -1) {
          return;
        }

        set(content, key, buffer[key]);
      });

      this.initializeBuffer(onlyTheseKeys);

      if ((0, _helpers.empty)(get(this, 'buffer'))) {
        set(this, 'hasBufferedChanges', false);
      }
    },
    discardBufferedChanges: function discardBufferedChanges(onlyTheseKeys) {
      var _this2 = this;

      var buffer = get(this, 'buffer');

      this.initializeBuffer(onlyTheseKeys);

      keys(buffer).forEach(function (key) {
        if (isArray(onlyTheseKeys) && onlyTheseKeys.indexOf(key) === -1) {
          return;
        }

        notifyPropertyChange(_this2, key);
      });

      if ((0, _helpers.empty)(get(this, 'buffer'))) {
        set(this, 'hasBufferedChanges', false);
      }
    },


    /*
     * Determines if a given key has changed else returns false. Allows individual key lookups where
     * as hasBufferedChanged only looks at the whole buffer.
     */
    hasChanged: function hasChanged(key) {
      var _getProperties3 = getProperties(this, ['buffer', 'content']),
          buffer = _getProperties3.buffer,
          content = _getProperties3.content;

      if (typeof key !== 'string' || typeof get(buffer, key) === 'undefined') {
        return false;
      }

      if (get(buffer, key) !== get(content, key)) {
        return true;
      }

      return false;
    }
  });
});