define('mobiledoc-kit/renderers/mobiledoc', ['exports', 'mobiledoc-kit/renderers/mobiledoc/0-2', 'mobiledoc-kit/renderers/mobiledoc/0-3', 'mobiledoc-kit/renderers/mobiledoc/0-3-1', 'mobiledoc-kit/utils/assert'], function (exports, _mobiledocKitRenderersMobiledoc02, _mobiledocKitRenderersMobiledoc03, _mobiledocKitRenderersMobiledoc031, _mobiledocKitUtilsAssert) {
  'use strict';

  var MOBILEDOC_VERSION = _mobiledocKitRenderersMobiledoc031.MOBILEDOC_VERSION;

  exports.MOBILEDOC_VERSION = MOBILEDOC_VERSION;
  exports['default'] = {
    render: function render(post, version) {
      switch (version) {
        case _mobiledocKitRenderersMobiledoc02.MOBILEDOC_VERSION:
          return _mobiledocKitRenderersMobiledoc02['default'].render(post);
        case _mobiledocKitRenderersMobiledoc03.MOBILEDOC_VERSION:
          return _mobiledocKitRenderersMobiledoc03['default'].render(post);
        case undefined:
        case null:
        case _mobiledocKitRenderersMobiledoc031.MOBILEDOC_VERSION:
          return _mobiledocKitRenderersMobiledoc031['default'].render(post);
        default:
          (0, _mobiledocKitUtilsAssert['default'])('Unknown version of mobiledoc renderer requested: ' + version, false);
      }
    }
  };
});