define('ember-inputmask/components/currency-input', ['exports', 'ember-inputmask/components/input-mask'], function (exports, _inputMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _inputMask.default.extend({
    oldComponent: '{{currency-input}}',
    newComponent: '{{one-way-currency-mask}}',
    mask: 'currency'
  });
});