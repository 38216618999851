define('mobiledoc-kit/parsers/mobiledoc', ['exports', 'mobiledoc-kit/parsers/mobiledoc/0-2', 'mobiledoc-kit/parsers/mobiledoc/0-3', 'mobiledoc-kit/parsers/mobiledoc/0-3-1', 'mobiledoc-kit/renderers/mobiledoc/0-2', 'mobiledoc-kit/renderers/mobiledoc/0-3', 'mobiledoc-kit/renderers/mobiledoc/0-3-1', 'mobiledoc-kit/utils/assert'], function (exports, _mobiledocKitParsersMobiledoc02, _mobiledocKitParsersMobiledoc03, _mobiledocKitParsersMobiledoc031, _mobiledocKitRenderersMobiledoc02, _mobiledocKitRenderersMobiledoc03, _mobiledocKitRenderersMobiledoc031, _mobiledocKitUtilsAssert) {
  'use strict';

  function parseVersion(mobiledoc) {
    return mobiledoc.version;
  }

  exports['default'] = {
    parse: function parse(builder, mobiledoc) {
      var version = parseVersion(mobiledoc);
      switch (version) {
        case _mobiledocKitRenderersMobiledoc02.MOBILEDOC_VERSION:
          return new _mobiledocKitParsersMobiledoc02['default'](builder).parse(mobiledoc);
        case _mobiledocKitRenderersMobiledoc03.MOBILEDOC_VERSION:
          return new _mobiledocKitParsersMobiledoc03['default'](builder).parse(mobiledoc);
        case _mobiledocKitRenderersMobiledoc031.MOBILEDOC_VERSION:
          return new _mobiledocKitParsersMobiledoc031['default'](builder).parse(mobiledoc);
        default:
          (0, _mobiledocKitUtilsAssert['default'])('Unknown version of mobiledoc parser requested: ' + version, false);
      }
    }
  };
});