define('ember-mobiledoc-editor/components/mobiledoc-link-prompt/component', ['exports', 'ember-mobiledoc-editor/components/mobiledoc-link-prompt/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    didInsertElement: function didInsertElement() {
      this.$('input').focus();
    }
  });
});