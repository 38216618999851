define('ember-g-map/components/g-map-polyline-coordinate', ['exports', 'ember-g-map/templates/components/g-map-polyline-coordinate', 'ember-g-map/components/g-map-polyline'], function (exports, _gMapPolylineCoordinate, _gMapPolyline) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var GMapPolylineCoordinateComponent = Ember.Component.extend({
    layout: _gMapPolylineCoordinate.default,
    classNames: ['g-map-polyline-coordinate'],

    polyline: Ember.computed.alias('polylineContext.polyline'),

    init: function init() {
      this._super.apply(this, arguments);

      var polylineContext = this.get('polylineContext');
      (false && !(polylineContext instanceof _gMapPolyline.default) && Ember.assert('Must be inside {{#g-map-polyline}} component with context set', polylineContext instanceof _gMapPolyline.default));


      polylineContext.registerCoordinate(this);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (Ember.isEmpty(this.get('coordinate')) && typeof google !== 'undefined') {
        var coordinate = new google.maps.LatLng();
        this.set('coordinate', coordinate);
      }
      this.setPosition();
    },
    willDestroyElement: function willDestroyElement() {
      this.get('polylineContext').unregisterCoordinate(this);
    },


    coordsChanged: Ember.observer('lat', 'lng', function () {
      Ember.run.once(this, 'setPosition');
    }),

    setPosition: function setPosition() {
      var polylineContext = this.get('polylineContext');
      var lat = this.get('lat');
      var lng = this.get('lng');

      if (Ember.isPresent(polylineContext) && Ember.isPresent(lat) && Ember.isPresent(lng) && typeof google !== 'undefined') {
        var coordinate = new google.maps.LatLng(lat, lng);
        this.set('coordinate', coordinate);
        polylineContext.setPath();
      }
    }
  });

  GMapPolylineCoordinateComponent.reopenClass({
    positionalParams: ['polylineContext']
  });

  exports.default = GMapPolylineCoordinateComponent;
});