define('ember-inputmask/components/credit-card-input', ['exports', 'ember-inputmask/components/input-mask'], function (exports, _inputMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _inputMask.default.extend({
    oldComponent: '{{credit-card-input}}',
    newComponent: '{{one-way-credit-card-mask}}',

    updateMask: function updateMask() {
      var cardType = this.get('cardType'),
          s = this.get('separator') || '-',
          // s for separator for convenience
      mask; // Also, we put the default in here instead
      // of defining it on the model

      if (cardType === 'American Express') {
        mask = '9999' + s + '999999' + s + '99999';
      } else if (cardType === 'Diners Club') {
        mask = '9999' + s + '999999' + s + '9999';
      } else {
        mask = '9999' + s + '9999' + s + '9999' + s + '9999';
      }

      if (this.get('mask') !== mask) {
        this.set('mask', mask);
      }
      this._super();
    },

    _maskShouldChange: Ember.observer('mask', 'cardType', 'separator', function () {
      Ember.run.once(this, 'updateMask');
    }),

    updateCardType: Ember.observer('unmaskedValue', function () {
      var unmaskedValue = this.get('unmaskedValue') || '',
          cardType;

      if (unmaskedValue.match(/^4/)) {
        cardType = 'Visa';
      } else if (unmaskedValue.match(/^5[1-5]/)) {
        cardType = 'MasterCard';
      } else if (unmaskedValue.match(/^3[47]/)) {
        cardType = 'American Express';
      } else if (unmaskedValue.match(/^3(?:0[0-5]|[68])/)) {
        cardType = 'Diners Club';
      } else if (unmaskedValue.match(/^6(?:011|5)/)) {
        cardType = 'Discover';
      } else if (unmaskedValue.match(/^(?:2131|1800|35)/)) {
        cardType = 'JCB';
      } else {
        cardType = 'Other';
      }

      this.set('cardType', cardType);
    })
  });
});