define('ember-inputmask/components/one-way-email-mask', ['exports', 'ember-inputmask/components/one-way-input-mask'], function (exports, _oneWayInputMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oneWayInputMask.default.extend({
    /**
     * @override
     */
    mask: 'email'
  });
});