define('mobiledoc-text-renderer', ['exports', 'mobiledoc-text-renderer/renderer-factory', 'mobiledoc-text-renderer/utils/render-type'], function (exports, _mobiledocTextRendererRendererFactory, _mobiledocTextRendererUtilsRenderType) {
  'use strict';

  exports.registerGlobal = registerGlobal;

  function registerGlobal(window) {
    window.MobiledocTextRenderer = _mobiledocTextRendererRendererFactory['default'];
  }

  exports.RENDER_TYPE = _mobiledocTextRendererUtilsRenderType['default'];
  exports['default'] = _mobiledocTextRendererRendererFactory['default'];
});