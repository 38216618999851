define('ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/renderer-factory', ['exports', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/renderers/0-2', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/renderers/0-3', 'ember-mobiledoc-dom-renderer/mobiledoc-dom-renderer/utils/render-type'], function (exports, _, _2, _renderType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  /**
   * runtime DOM renderer
   * renders a mobiledoc to DOM
   *
   * input: mobiledoc
   * output: DOM
   */

  function validateCards(cards) {
    if (!Array.isArray(cards)) {
      throw new Error('`cards` must be passed as an array');
    }
    for (var i = 0; i < cards.length; i++) {
      var card = cards[i];
      if (card.type !== _renderType.default) {
        throw new Error('Card "' + card.name + '" must be of type "' + _renderType.default + '", was "' + card.type + '"');
      }
      if (!card.render) {
        throw new Error('Card "' + card.name + '" must define `render`');
      }
    }
  }

  function validateAtoms(atoms) {
    if (!Array.isArray(atoms)) {
      throw new Error('`atoms` must be passed as an array');
    }
    for (var i = 0; i < atoms.length; i++) {
      var atom = atoms[i];
      if (atom.type !== _renderType.default) {
        throw new Error('Atom "' + atom.name + '" must be type "' + _renderType.default + '", was "' + atom.type + '"');
      }
      if (!atom.render) {
        throw new Error('Atom "' + atom.name + '" must define `render`');
      }
    }
  }

  var RendererFactory = function () {
    function RendererFactory() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref$cards = _ref.cards,
          cards = _ref$cards === undefined ? [] : _ref$cards,
          _ref$atoms = _ref.atoms,
          atoms = _ref$atoms === undefined ? [] : _ref$atoms,
          _ref$cardOptions = _ref.cardOptions,
          cardOptions = _ref$cardOptions === undefined ? {} : _ref$cardOptions,
          unknownCardHandler = _ref.unknownCardHandler,
          unknownAtomHandler = _ref.unknownAtomHandler,
          _ref$markupElementRen = _ref.markupElementRenderer,
          markupElementRenderer = _ref$markupElementRen === undefined ? {} : _ref$markupElementRen,
          _ref$sectionElementRe = _ref.sectionElementRenderer,
          sectionElementRenderer = _ref$sectionElementRe === undefined ? {} : _ref$sectionElementRe,
          dom = _ref.dom,
          _ref$markupSanitizer = _ref.markupSanitizer,
          markupSanitizer = _ref$markupSanitizer === undefined ? null : _ref$markupSanitizer;

      _classCallCheck(this, RendererFactory);

      validateCards(cards);
      validateAtoms(atoms);

      if (!dom) {
        if (typeof window === 'undefined') {
          throw new Error('A `dom` option must be provided to the renderer when running without window.document');
        }
        dom = window.document;
      }

      this.options = {
        cards: cards,
        atoms: atoms,
        cardOptions: cardOptions,
        unknownCardHandler: unknownCardHandler,
        unknownAtomHandler: unknownAtomHandler,
        markupElementRenderer: markupElementRenderer,
        sectionElementRenderer: sectionElementRenderer,
        dom: dom,
        markupSanitizer: markupSanitizer
      };
    }

    _createClass(RendererFactory, [{
      key: 'render',
      value: function render(mobiledoc) {
        var version = mobiledoc.version;

        switch (version) {
          case _.MOBILEDOC_VERSION:
          case undefined:
          case null:
            return new _.default(mobiledoc, this.options).render();
          case _2.MOBILEDOC_VERSION_0_3_0:
          case _2.MOBILEDOC_VERSION_0_3_1:
            return new _2.default(mobiledoc, this.options).render();
          default:
            throw new Error('Unexpected Mobiledoc version "' + version + '"');
        }
      }
    }]);

    return RendererFactory;
  }();

  exports.default = RendererFactory;
});