define('ember-mobiledoc-editor/components/mobiledoc-editor/component', ['exports', 'ember-mobiledoc-editor/components/mobiledoc-editor/template', 'mobiledoc-kit/editor/editor', 'mobiledoc-kit/renderers/mobiledoc', 'ember-mobiledoc-editor/utils/polyfill-assign'], function (exports, _template, _editor, _mobiledoc, _polyfillAssign) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.TESTING_EXPANDO_PROPERTY = exports.DID_CREATE_EDITOR_ACTION = exports.WILL_CREATE_EDITOR_ACTION = exports.REMOVE_ATOM_HOOK = exports.ADD_ATOM_HOOK = exports.REMOVE_CARD_HOOK = exports.ADD_CARD_HOOK = undefined;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var ADD_CARD_HOOK = exports.ADD_CARD_HOOK = 'addComponent';
  var REMOVE_CARD_HOOK = exports.REMOVE_CARD_HOOK = 'removeComponent';
  var ADD_ATOM_HOOK = exports.ADD_ATOM_HOOK = 'addAtomComponent';
  var REMOVE_ATOM_HOOK = exports.REMOVE_ATOM_HOOK = 'removeAtomComponent';
  var WILL_CREATE_EDITOR_ACTION = exports.WILL_CREATE_EDITOR_ACTION = 'will-create-editor';
  var DID_CREATE_EDITOR_ACTION = exports.DID_CREATE_EDITOR_ACTION = 'did-create-editor';

  var TESTING_EXPANDO_PROPERTY = exports.TESTING_EXPANDO_PROPERTY = '__mobiledoc_kit_editor';

  var EDITOR_CARD_SUFFIX = '-editor';
  var EMPTY_MOBILEDOC = {
    version: _mobiledoc.MOBILEDOC_VERSION,
    markups: [],
    atoms: [],
    cards: [],
    sections: []
  };

  function arrayToMap(array) {
    var map = Object.create(null);
    array.forEach(function (key) {
      if (key) {
        // skip undefined/falsy key values
        key = 'is' + Ember.String.capitalize(Ember.String.camelize(key));
        map[key] = true;
      }
    });
    return map;
  }

  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'article',
    classNames: ['mobiledoc-editor'],

    placeholder: 'Write here...',
    spellcheck: true,
    autofocus: true,
    serializeVersion: _mobiledoc.MOBILEDOC_VERSION,

    options: null,

    // merge in named options with the `options` property data-bag
    editorOptions: Ember.computed(function () {
      var options = this.get('options') || {};

      return (0, _polyfillAssign.default)({
        placeholder: this.get('placeholder'),
        spellcheck: this.get('spellcheck'),
        autofocus: this.get('autofocus'),
        cardOptions: this.get('cardOptions'),
        cards: this.get('cards') || [],
        atoms: this.get('atoms') || []
      }, options);
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var mobiledoc = this.get('mobiledoc');
      if (!mobiledoc) {
        mobiledoc = EMPTY_MOBILEDOC;
        this.set('mobiledoc', mobiledoc);
      }
      this.set('componentCards', Ember.A([]));
      this.set('componentAtoms', Ember.A([]));
      this.set('linkOffsets', null);
      this.set('activeMarkupTagNames', {});
      this.set('activeSectionTagNames', {});
      this._startedRunLoop = false;
    },


    actions: {
      toggleMarkup: function toggleMarkup(markupTagName) {
        var editor = this.get('editor');
        editor.toggleMarkup(markupTagName);
      },
      toggleSection: function toggleSection(sectionTagName) {
        var editor = this.get('editor');
        editor.toggleSection(sectionTagName);
      },
      addCard: function addCard(cardName) {
        var payload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        this._addCard(cardName, payload);
      },
      addAtom: function addAtom(atomName) {
        var text = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
        var payload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        this._addAtom(atomName, text, payload);
      },
      addCardInEditMode: function addCardInEditMode(cardName) {
        var payload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var editMode = true;
        this._addCard(cardName, payload, editMode);
      },
      toggleLink: function toggleLink() {
        var editor = this.get('editor');
        if (!editor.hasCursor()) {
          return;
        }
        if (editor.hasActiveMarkup('a')) {
          editor.toggleMarkup('a');
        } else {
          this.set('linkOffsets', editor.range);
        }
      },
      completeLink: function completeLink(href) {
        var offsets = this.get('linkOffsets');
        this.set('linkOffsets', null);
        var editor = this.get('editor');
        editor.selectRange(offsets);
        editor.toggleMarkup('a', { href: href });
      },
      cancelLink: function cancelLink() {
        this.set('linkOffsets', null);
      }
    },

    editingContexts: Ember.computed(function () {
      return Ember.A([]);
    }),

    willRender: function willRender() {
      var _this = this,
          _componentHooks;

      // Use a default mobiledoc. If there are no changes, then return
      // early.
      var mobiledoc = this.get('mobiledoc') || EMPTY_MOBILEDOC;
      if ((this._localMobiledoc && this._localMobiledoc === mobiledoc || this._upstreamMobiledoc && this._upstreamMobiledoc === mobiledoc) && this._lastIsEditingDisabled === this.get('isEditingDisabled')) {
        // No change to mobiledoc, no need to recreate the editor
        return;
      }
      this._lastIsEditingDisabled = this.get('isEditingDisabled');
      this._upstreamMobiledoc = mobiledoc;
      this._localMobiledoc = null;

      this.willCreateEditor();

      // Teardown any old editor that might be around.
      var editor = this.get('editor');
      if (editor) {
        editor.destroy();
      }

      // Create a new editor.
      var editorOptions = this.get('editorOptions');
      editorOptions.mobiledoc = mobiledoc;
      var componentHooks = (_componentHooks = {}, _defineProperty(_componentHooks, ADD_CARD_HOOK, function (_ref) {
        var env = _ref.env,
            options = _ref.options,
            payload = _ref.payload;
        var isEditing = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        var cardId = Ember.uuid();
        var cardName = env.name;
        if (isEditing) {
          cardName = cardName + EDITOR_CARD_SUFFIX;
        }
        var destinationElementId = 'mobiledoc-editor-card-' + cardId;
        var element = document.createElement('div');
        element.id = destinationElementId;

        // The data must be copied to avoid sharing the reference
        payload = Ember.copy(payload, true);

        var card = Ember.Object.create({
          destinationElementId: destinationElementId,
          cardName: cardName,
          payload: payload,
          env: env,
          options: options,
          editor: editor,
          postModel: env.postModel
        });
        Ember.run.schedule('afterRender', function () {
          _this.get('componentCards').pushObject(card);
        });
        return { card: card, element: element };
      }), _defineProperty(_componentHooks, ADD_ATOM_HOOK, function (_ref2) {
        var env = _ref2.env,
            options = _ref2.options,
            payload = _ref2.payload,
            value = _ref2.value;

        var atomId = Ember.uuid();
        var atomName = env.name;
        var destinationElementId = 'mobiledoc-editor-atom-' + atomId;
        var element = document.createElement('span');
        element.id = destinationElementId;

        // The data must be copied to avoid sharing the reference
        payload = Ember.copy(payload, true);

        var atom = Ember.Object.create({
          destinationElementId: destinationElementId,
          atomName: atomName,
          payload: payload,
          value: value,
          callbacks: env,
          options: options,
          editor: editor,
          postModel: env.postModel
        });
        Ember.run.schedule('afterRender', function () {
          _this.get('componentAtoms').pushObject(atom);
        });
        return { atom: atom, element: element };
      }), _defineProperty(_componentHooks, REMOVE_CARD_HOOK, function (card) {
        _this.get('componentCards').removeObject(card);
      }), _defineProperty(_componentHooks, REMOVE_ATOM_HOOK, function (atom) {
        _this.get('componentAtoms').removeObject(atom);
      }), _componentHooks);
      if (editorOptions.cardOptions) {
        editorOptions.cardOptions = (0, _polyfillAssign.default)(editorOptions.cardOptions, componentHooks);
      } else {
        editorOptions.cardOptions = componentHooks;
      }
      editor = new _editor.default(editorOptions);
      editor.willRender(function () {
        // The editor's render/rerender will happen after this `editor.willRender`,
        // so we explicitly start a runloop here if there is none, so that the
        // add/remove card hooks happen inside a runloop.
        // When pasting text that gets turned into a card, for example,
        // the add card hook would run outside the runloop if we didn't begin a new
        // one now.
        if (!Ember.run.currentRunLoop) {
          _this._startedRunLoop = true;
          Ember.run.begin();
        }
      });
      editor.didRender(function () {
        // If we had explicitly started a run loop in `editor.willRender`,
        // we must explicitly end it here.
        if (_this._startedRunLoop) {
          _this._startedRunLoop = false;
          Ember.run.end();
        }
      });
      editor.postDidChange(function () {
        Ember.run.join(function () {
          _this.postDidChange(editor);
        });
      });
      editor.inputModeDidChange(function () {
        if (_this.isDestroyed) {
          return;
        }
        Ember.run.join(function () {
          _this.inputModeDidChange(editor);
        });
      });
      editor.cursorDidChange(function () {
        if (_this.isDestroyed) {
          return;
        }
        Ember.run.join(function () {
          _this.cursorDidChange(editor);
        });
      });
      if (this.get('isEditingDisabled')) {
        editor.disableEditing();
      }
      this.set('editor', editor);
      this.didCreateEditor(editor);
    },
    didRender: function didRender() {
      var editor = this.get('editor');
      if (!editor.hasRendered) {
        var editorElement = this.$('.mobiledoc-editor__editor')[0];
        this._isRenderingEditor = true;
        try {
          editor.render(editorElement);
        } catch (e) {
          Ember.run.schedule('afterRender', function () {
            throw e;
          });
        }
        this._isRenderingEditor = false;
      }
      this._setExpandoProperty(editor);
    },
    willDestroyElement: function willDestroyElement() {
      var editor = this.get('editor');
      editor.destroy();
    },
    postDidChange: function postDidChange(editor) {
      var serializeVersion = this.get('serializeVersion');
      var updatedMobileDoc = editor.serialize(serializeVersion);
      this._localMobiledoc = updatedMobileDoc;
      this.sendAction('on-change', updatedMobileDoc); // eslint-disable-line ember/closure-actions
    },
    inputModeDidChange: function inputModeDidChange(editor) {
      var _this2 = this;

      var markupTags = arrayToMap(editor.activeMarkups.map(function (m) {
        return m.tagName;
      }));
      // editor.activeSections are leaf sections.
      // Map parent section tag names (e.g. 'p', 'ul', 'ol') so that list buttons
      // are updated.
      var sectionParentTagNames = editor.activeSections.map(function (s) {
        return s.isNested ? s.parent.tagName : s.tagName;
      });
      var sectionTags = arrayToMap(sectionParentTagNames);

      // Avoid updating this component's properties synchronously while
      // rendering the editor (after rendering the component) because it
      // causes Ember to display deprecation warnings
      if (this._isRenderingEditor) {
        Ember.run.schedule('afterRender', function () {
          _this2.set('activeMarkupTagNames', markupTags);
          _this2.set('activeSectionTagNames', sectionTags);
        });
      } else {
        this.set('activeMarkupTagNames', markupTags);
        this.set('activeSectionTagNames', sectionTags);
      }
    },
    cursorDidChange: function cursorDidChange() /*editor*/{},
    willCreateEditor: function willCreateEditor() {
      this.sendAction(WILL_CREATE_EDITOR_ACTION); // eslint-disable-line ember/closure-actions
    },
    didCreateEditor: function didCreateEditor(editor) {
      this.sendAction(DID_CREATE_EDITOR_ACTION, editor); // eslint-disable-line ember/closure-actions
    },
    _addAtom: function _addAtom(atomName, text, payload) {
      var editor = this.get('editor');
      editor.insertAtom(atomName, text, payload);
    },
    _addCard: function _addCard(cardName, payload) {
      var editMode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var editor = this.get('editor');
      editor.insertCard(cardName, payload, editMode);
    },
    _setExpandoProperty: function _setExpandoProperty(editor) {
      // Store a reference to the editor for the acceptance test helpers
      if (this.element && Ember.testing) {
        this.element[TESTING_EXPANDO_PROPERTY] = editor;
      }
    }
  });
});