define('mobiledoc-text-renderer/renderers/0-2', ['exports', 'mobiledoc-text-renderer/cards/image', 'mobiledoc-text-renderer/utils/render-type', 'mobiledoc-text-renderer/utils/section-types'], function (exports, _mobiledocTextRendererCardsImage, _mobiledocTextRendererUtilsRenderType, _mobiledocTextRendererUtilsSectionTypes) {
  /**
   * runtime Text renderer
   * renders a mobiledoc to Text
   *
   * input: mobiledoc
   * output: Text (string)
   */
  'use strict';

  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var LINE_BREAK = '\n';

  var MOBILEDOC_VERSION = '0.2.0';

  exports.MOBILEDOC_VERSION = MOBILEDOC_VERSION;
  function validateVersion(version) {
    if (version !== MOBILEDOC_VERSION) {
      throw new Error('Unexpected Mobiledoc version "' + version + '"');
    }
  }

  var Renderer = (function () {
    function Renderer(mobiledoc, state) {
      _classCallCheck(this, Renderer);

      var cards = state.cards;
      var cardOptions = state.cardOptions;
      var atoms = state.atoms;
      var unknownCardHandler = state.unknownCardHandler;
      var version = mobiledoc.version;
      var sectionData = mobiledoc.sections;

      validateVersion(version);

      var _sectionData = _slicedToArray(sectionData, 2);

      var sections = _sectionData[1];

      this.root = [];
      this.sections = sections;
      this.cards = cards;
      this.atoms = atoms;
      this.cardOptions = cardOptions;
      this.unknownCardHandler = unknownCardHandler || this._defaultUnknownCardHandler;

      this._teardownCallbacks = [];
    }

    _createClass(Renderer, [{
      key: 'render',
      value: function render() {
        var _this = this;

        this.sections.forEach(function (section) {
          _this.root.push(_this.renderSection(section));
        });

        var result = this.root.join(LINE_BREAK);
        return { result: result, teardown: function teardown() {
            return _this.teardown();
          } };
      }
    }, {
      key: 'teardown',
      value: function teardown() {
        for (var i = 0; i < this._teardownCallbacks.length; i++) {
          this._teardownCallbacks[i]();
        }
      }
    }, {
      key: 'renderSection',

      // for the text renderer, a missing card is a no-op
      value: function renderSection(section) {
        var _section = _slicedToArray(section, 1);

        var type = _section[0];

        switch (type) {
          case _mobiledocTextRendererUtilsSectionTypes.MARKUP_SECTION_TYPE:
            return this.renderMarkupSection(section);
          case _mobiledocTextRendererUtilsSectionTypes.IMAGE_SECTION_TYPE:
            return this.renderImageSection(section);
          case _mobiledocTextRendererUtilsSectionTypes.LIST_SECTION_TYPE:
            return this.renderListSection(section);
          case _mobiledocTextRendererUtilsSectionTypes.CARD_SECTION_TYPE:
            return this.renderCardSection(section);
          default:
            throw new Error('Unimplemented renderer for type ' + type);
        }
      }
    }, {
      key: 'renderImageSection',
      value: function renderImageSection() {
        return '';
      }
    }, {
      key: 'renderListSection',
      value: function renderListSection(_ref) {
        var _this2 = this;

        var _ref2 = _slicedToArray(_ref, 3);

        var type = _ref2[0];
        var tagName = _ref2[1];
        var items = _ref2[2];

        return items.map(function (li) {
          return _this2.renderListItem(li);
        }).join(LINE_BREAK);
      }
    }, {
      key: 'renderListItem',
      value: function renderListItem(markers) {
        return this.renderMarkers(markers);
      }
    }, {
      key: 'findCard',
      value: function findCard(name) {
        for (var i = 0; i < this.cards.length; i++) {
          if (this.cards[i].name === name) {
            return this.cards[i];
          }
        }
        if (name === _mobiledocTextRendererCardsImage['default'].name) {
          return _mobiledocTextRendererCardsImage['default'];
        }
        return this._createUnknownCard(name);
      }
    }, {
      key: '_createUnknownCard',
      value: function _createUnknownCard(name) {
        return {
          name: name,
          type: _mobiledocTextRendererUtilsRenderType['default'],
          render: this.unknownCardHandler
        };
      }
    }, {
      key: 'renderCardSection',
      value: function renderCardSection(_ref3) {
        var _ref32 = _slicedToArray(_ref3, 3);

        var type = _ref32[0];
        var name = _ref32[1];
        var payload = _ref32[2];

        var card = this.findCard(name);

        var cardArg = this._createCardArgument(card, payload);
        var rendered = card.render(cardArg);

        this._validateCardRender(rendered, card.name);

        return rendered || '';
      }
    }, {
      key: '_validateCardRender',
      value: function _validateCardRender(rendered, cardName) {
        if (!rendered) {
          return;
        }

        if (typeof rendered !== 'string') {
          throw new Error('Card "' + cardName + '" must render ' + _mobiledocTextRendererUtilsRenderType['default'] + ', but result was ' + typeof rendered + '"');
        }
      }
    }, {
      key: '_registerTeardownCallback',
      value: function _registerTeardownCallback(callback) {
        this._teardownCallbacks.push(callback);
      }
    }, {
      key: '_createCardArgument',
      value: function _createCardArgument(card) {
        var _this3 = this;

        var payload = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

        var env = {
          name: card.name,
          isInEditor: false,
          onTeardown: function onTeardown(callback) {
            return _this3._registerTeardownCallback(callback);
          }
        };

        var options = this.cardOptions;

        return { env: env, options: options, payload: payload };
      }
    }, {
      key: 'renderMarkupSection',
      value: function renderMarkupSection(_ref4) {
        var _ref42 = _slicedToArray(_ref4, 3);

        var type = _ref42[0];
        var tagName = _ref42[1];
        var markers = _ref42[2];

        return this.renderMarkers(markers);
      }
    }, {
      key: 'renderMarkers',
      value: function renderMarkers(markers) {
        var str = '';
        markers.forEach(function (m) {
          var _m = _slicedToArray(m, 3);

          var text = _m[2];

          str += text;
        });
        return str;
      }
    }, {
      key: '_defaultUnknownCardHandler',
      get: function get() {
        return function () {};
      }
    }]);

    return Renderer;
  })();

  exports['default'] = Renderer;
});