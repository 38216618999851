define('mobiledoc-kit/models/types', ['exports'], function (exports) {
  'use strict';

  var MARKUP_SECTION_TYPE = 'markup-section';
  exports.MARKUP_SECTION_TYPE = MARKUP_SECTION_TYPE;
  var LIST_SECTION_TYPE = 'list-section';
  exports.LIST_SECTION_TYPE = LIST_SECTION_TYPE;
  var MARKUP_TYPE = 'markup';
  exports.MARKUP_TYPE = MARKUP_TYPE;
  var MARKER_TYPE = 'marker';
  exports.MARKER_TYPE = MARKER_TYPE;
  var POST_TYPE = 'post';
  exports.POST_TYPE = POST_TYPE;
  var LIST_ITEM_TYPE = 'list-item';
  exports.LIST_ITEM_TYPE = LIST_ITEM_TYPE;
  var CARD_TYPE = 'card-section';
  exports.CARD_TYPE = CARD_TYPE;
  var IMAGE_SECTION_TYPE = 'image-section';
  exports.IMAGE_SECTION_TYPE = IMAGE_SECTION_TYPE;
  var ATOM_TYPE = 'atom';
  exports.ATOM_TYPE = ATOM_TYPE;
});