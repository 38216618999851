define('ember-inputmask/components/one-way-phone-mask', ['exports', 'ember-inputmask/components/one-way-input-mask'], function (exports, _oneWayInputMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oneWayInputMask.default.extend({
    NON_ATTRIBUTE_BOUND_PROPS: _oneWayInputMask.DEFAULT_NON_BOUND_PROPS.concat('extensions'),

    /**
     * Whether or not to include extension in the mask
     */
    extensions: false,

    /**
     * @override
     */
    mask: Ember.computed('extensions', function () {
      if (Ember.get(this, 'extensions')) {
        return '(999) 999-9999[ x 9{1,4}]';
      }

      return '(999) 999-9999';
    })
  });
});