define("ember-g-map/templates/components/g-map-address-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CV3obYaF",
    "block": "{\"symbols\":[\"routeContext\",\"&default\"],\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[4,\"g-map-route\",[[24,[\"mapContext\"]]],[[\"zIndex\",\"strokeColor\",\"strokeWeight\",\"strokeOpacity\",\"originLat\",\"originLng\",\"destinationLat\",\"destinationLng\"],[[24,[\"zIndex\"]],[24,[\"strokeColor\"]],[24,[\"strokeWeight\"]],[24,[\"strokeOpacity\"]],[24,[\"originLat\"]],[24,[\"originLng\"]],[24,[\"destinationLat\"]],[24,[\"destinationLng\"]]]],{\"statements\":[[0,\"    \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"g-map-route\",[[24,[\"mapContext\"]]],[[\"zIndex\",\"strokeColor\",\"strokeWeight\",\"strokeOpacity\",\"originLat\",\"originLng\",\"destinationLat\",\"destinationLng\"],[[24,[\"zIndex\"]],[24,[\"strokeColor\"]],[24,[\"strokeWeight\"]],[24,[\"strokeOpacity\"]],[24,[\"originLat\"]],[24,[\"originLng\"]],[24,[\"destinationLat\"]],[24,[\"destinationLng\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-g-map/templates/components/g-map-address-route.hbs"
    }
  });

  _exports.default = _default;
});