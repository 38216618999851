define('ember-mobiledoc-dom-renderer/components/render-mobiledoc', ['exports', 'ember-mobiledoc-dom-renderer', 'ember-mobiledoc-dom-renderer/templates/components/render-mobiledoc', 'ember-mobiledoc-dom-renderer/utils/document', 'ember-mobiledoc-dom-renderer/utils/polyfilled-assign'], function (exports, _emberMobiledocDomRenderer, _renderMobiledoc2, _document, _polyfilledAssign) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ATOM_ELEMENT_CLASS = exports.CARD_ELEMENT_CLASS = undefined;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var assert = Ember.assert,
      computed = Ember.computed,
      join = Ember.run.join,
      uuid = Ember.uuid;


  var ADD_CARD_HOOK = 'addComponentCard';
  var REMOVE_CARD_HOOK = 'removeComponentCard';
  var ADD_ATOM_HOOK = 'addComponentAtom';
  var REMOVE_ATOM_HOOK = 'removeComponentAtom';
  var CARD_TAG_NAME = 'div';
  var ATOM_TAG_NAME = 'span';
  var UUID_PREFIX = '__rendered-mobiledoc-entity-';
  var CARD_ELEMENT_CLASS = exports.CARD_ELEMENT_CLASS = '__rendered-mobiledoc-card';
  var ATOM_ELEMENT_CLASS = exports.ATOM_ELEMENT_CLASS = '__rendered-mobiledoc-atom';

  var CARD_HOOKS = {
    ADD: ADD_CARD_HOOK,
    REMOVE: REMOVE_CARD_HOOK
  };

  var ATOM_HOOKS = {
    ADD: ADD_ATOM_HOOK,
    REMOVE: REMOVE_ATOM_HOOK
  };

  function rendererFor(type) {
    var hookNames = void 0;

    if (type === 'card') {
      hookNames = CARD_HOOKS;
    } else if (type === 'atom') {
      hookNames = ATOM_HOOKS;
    }

    return function (_ref) {
      var env = _ref.env,
          options = _ref.options;
      var onTeardown = env.onTeardown;

      var addHook = options[hookNames.ADD];
      var removeHook = options[hookNames.REMOVE];

      var _addHook = addHook.apply(undefined, arguments),
          entity = _addHook.entity,
          element = _addHook.element;

      onTeardown(function () {
        return removeHook(entity);
      });

      return element;
    };
  }

  function createComponentCard(name) {
    return {
      name: name,
      type: _emberMobiledocDomRenderer.RENDER_TYPE,
      render: rendererFor('card')
    };
  }

  function createComponentAtom(name) {
    return {
      name: name,
      type: _emberMobiledocDomRenderer.RENDER_TYPE,
      render: rendererFor('atom')
    };
  }

  exports.default = Ember.Component.extend({
    layout: _renderMobiledoc2.default,

    didReceiveAttrs: function didReceiveAttrs() {
      var mobiledoc = this.get('mobiledoc');
      assert('Must pass mobiledoc to render-mobiledoc component', !!mobiledoc);

      if (this._teardownRender) {
        this._teardownRender();
        this._teardownRender = null;
      }
      this._renderMobiledoc();
    },


    // pass in an array of card names that the mobiledoc may have. These
    // map to component names using `cardNameToComponentName`
    cardNames: [],

    // pass in an array of atom names that the mobiledoc may have. These
    // map to component names using `atomNameToComponentName`
    atomNames: [],

    _mdcCards: computed('cardNames', function () {
      return this.get('cardNames').map(function (name) {
        return createComponentCard(name);
      });
    }),

    _mdcAtoms: computed('atomNames', function () {
      return this.get('atomNames').map(function (name) {
        return createComponentAtom(name);
      });
    }),

    _renderMobiledoc: function _renderMobiledoc() {
      var _this = this;

      var dom = (0, _document.getDocument)(this);

      var mobiledoc = this.get('mobiledoc');

      var options = {
        dom: dom,
        cards: this.get('_mdcCards'),
        atoms: this.get('_mdcAtoms')
      };
      ['mobiledoc', 'sectionElementRenderer', 'markupElementRenderer', 'unknownCardHandler', 'unknownAtomHandler'].forEach(function (option) {
        var value = _this.get(option);
        if (value) {
          options[option] = value;
        }
      });

      var passedOptions = this.get('cardOptions');
      var cardOptions = this.get('_cardOptions');
      options.cardOptions = passedOptions ? (0, _polyfilledAssign.default)(passedOptions, cardOptions) : cardOptions;

      var renderer = new _emberMobiledocDomRenderer.default(options);

      var _renderer$render = renderer.render(mobiledoc),
          result = _renderer$render.result,
          teardown = _renderer$render.teardown;

      // result is a document fragment, and glimmer2 errors when cleaning it up.
      // We must append the document fragment to a static wrapper.
      // Related: https://github.com/tildeio/glimmer/pull/331 and
      //          https://github.com/yapplabs/ember-wormhole/issues/66#issuecomment-246207622


      var wrapper = this._createElement(dom, 'div');
      wrapper.appendChild(result);

      this.set('renderedMobiledoc', wrapper);
      this._teardownRender = teardown;
    },


    _cardOptions: computed(function () {
      var _this2 = this,
          _ref4;

      return _ref4 = {}, _defineProperty(_ref4, ADD_CARD_HOOK, function (_ref2) {
        var env = _ref2.env,
            options = _ref2.options,
            payload = _ref2.payload;
        var cardName = env.name,
            dom = env.dom;

        var classNames = [CARD_ELEMENT_CLASS, CARD_ELEMENT_CLASS + '-' + cardName];
        var element = _this2._createElement(dom, CARD_TAG_NAME, classNames);
        var componentName = _this2.cardNameToComponentName(cardName);

        var card = {
          componentName: componentName,
          destinationElementId: element.getAttribute('id'),
          payload: payload,
          options: options
        };
        _this2.addCard(card);
        return { entity: card, element: element };
      }), _defineProperty(_ref4, ADD_ATOM_HOOK, function (_ref3) {
        var env = _ref3.env,
            options = _ref3.options,
            value = _ref3.value,
            payload = _ref3.payload;
        var atomName = env.name,
            dom = env.dom;

        var classNames = [ATOM_ELEMENT_CLASS, ATOM_ELEMENT_CLASS + '-' + atomName];
        var element = _this2._createElement(dom, ATOM_TAG_NAME, classNames);
        var componentName = _this2.atomNameToComponentName(atomName);

        var atom = {
          componentName: componentName,
          destinationElementId: element.getAttribute('id'),
          payload: payload,
          value: value,
          options: options
        };
        _this2.addAtom(atom);
        return { entity: atom, element: element };
      }), _defineProperty(_ref4, REMOVE_CARD_HOOK, function (card) {
        return _this2.removeCard(card);
      }), _defineProperty(_ref4, REMOVE_ATOM_HOOK, function (atom) {
        return _this2.removeAtom(atom);
      }), _ref4;
    }),

    willDestroyElement: function willDestroyElement() {
      if (this._teardownRender) {
        this._teardownRender();
      }
      return this._super.apply(this, arguments);
    },


    // override in subclass to change the mapping of card name -> component name
    cardNameToComponentName: function cardNameToComponentName(name) {
      return name;
    },


    // override in subclass to change the mapping of atom name -> component name
    atomNameToComponentName: function atomNameToComponentName(name) {
      return name;
    },


    // @private

    _componentCards: computed(function () {
      return Ember.A();
    }),

    _componentAtoms: computed(function () {
      return Ember.A();
    }),

    addCard: function addCard(card) {
      this.get('_componentCards').pushObject(card);
    },
    removeCard: function removeCard(card) {
      var _this3 = this;

      join(function () {
        _this3.get('_componentCards').removeObject(card);
      });
    },
    addAtom: function addAtom(atom) {
      this.get('_componentAtoms').pushObject(atom);
    },
    removeAtom: function removeAtom(atom) {
      var _this4 = this;

      join(function () {
        _this4.get('_componentAtoms').removeObject(atom);
      });
    },
    generateUuid: function generateUuid() {
      return '' + UUID_PREFIX + uuid();
    },
    _createElement: function _createElement(dom, tagName) {
      var classNames = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

      var el = dom.createElement(tagName);
      el.setAttribute('id', this.generateUuid());
      el.setAttribute('class', classNames.join(' '));
      return el;
    }
  });
});